<footer class="footer-area">
    <div class="row align-items-center">
        <div class="col-lg-6 col-sm-6 col-md-6">
            <p>Copyright <i class='bx bx-copyright'></i> {{ year }} <a href="#">Express Delivery</a>. All
                rights reserved</p>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 text-right">
            <p>Powered by <a href="https://xlexpressandlogistcs.com/" target="_blank">XL Express & Logistics Ltd.</a>
            </p>
        </div>
    </div>
</footer>
import { Component, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { RidersService } from 'src/app/services/riders.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-new-rider',
  templateUrl: './new-rider.component.html',
  styleUrls: ['./new-rider.component.css']
})
export class NewRiderComponent implements OnInit {

  hasError = false
  isLoading = false
  canProceed = false

  uid: any = 0
  resp: any

  selectedUser: any = []

  constructor(private matDialog: MatDialog, public dialogRef: MatDialogRef<NewRiderComponent>, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService, private riderService: RidersService, private settingService: SettingsService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.uid = _localStorageService.get('uid')
  }

  ngOnInit(): void {
  }

  keyword = 'firstName';
  selectUserEvent(item: any) {
    this.selectedUser = item
    this.canProceed = true
  }

  async onSubmit() {
    if (this.canProceed) {
      this.isLoading = true

      let data = {
        id: this.selectedUser.id,
        gender: this.selectedUser.gender,
        imageUrl: this.selectedUser.imageUrl,
        phone: this.selectedUser.phone,
        position: [],
        online: true,
        rating: 0,
        name: this.selectedUser.firstName + ' ' + this.selectedUser.lastName,
        trips: 0,
        votes: 0,
        lat: 6.4686726,
        lng: 3.5713047,
      }

      await this.riderService.createNewRider(data).then(res => {
        // Update User Record
        let d = { role: 'Rider' }
        this.settingService.updateUser(this.selectedUser.id, d)

        this.isLoading = false;
        this.dialogRef.close()
        this.toastr.success('Slected user has be converted to a rider', 'Request Successful')

        // window.location.reload();
      }).catch(err => {
        console.log(err)
        this.toastr.success(err, 'Request Failed')
      })
    }

  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { Payment, Wallet } from 'src/app/interfaces/payment';
import { Profile } from 'src/app/interfaces/profile';
import { SettingsService } from 'src/app/services/settings.service';
import { Theme } from 'src/app/services/theme-toggle.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-wallets',
  templateUrl: './wallets.component.html',
  styleUrls: ['./wallets.component.css']
})
export class WalletsComponent implements OnInit {
  theme: Theme = 'light'
  isDark: boolean = false

  title = "Wallets"
  icon = "bx bx-cog"
  crumbs = [
    { title: "Dashboard", icon: "bx bx-home", url: "/dashboard" },
    { title: "Wallets", icon: "bx bx-money", url: "/wallets" },
    { title: "Overview", icon: "", url: null },
  ]

  users: any

  wallets: Wallet[] = [];

  // data Table
  ELEMENT_DATA: Wallet[] = []
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns: string[] = [
    'id',
    'role',
    'balance',
    'actions',
  ];

  // PAss data source
  dataSource = new MatTableDataSource<Wallet>(this.ELEMENT_DATA)

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Wallet>;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  constructor(private titleService: Title, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService, private walletService: WalletService, private settingsService: SettingsService, private router: Router) {
    this.titleService.setTitle(this.title);

    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
  }

  ngOnInit(): void {
    this.fetchUsers()
    this.fetchData()
  }

  // Fetch All Users
  fetchData() {
    this.walletService.getAllWallets().subscribe(res => {
      this.wallets = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.wallets.push(item as Wallet)
      })
      this.dataSource.data = this.wallets
    })
  }

  // Filter table
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // Fetch All Users
  fetchUsers() {
    this.settingsService.getAllUsers().subscribe(res => {
      this.users = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.users.push(item as Profile)
      })
    })
  }

  // navigate to payments page
  viewPayments(data: any) {
    const navigationExtras: NavigationExtras = { state: { wid: data.id, balance: data.balance } };
    this.router.navigate(['wallet/payments'], navigationExtras);
  }

}

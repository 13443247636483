<app-breadcrumb [title]="title" [icon]="icon" [crumbs]="crumbs"></app-breadcrumb>

<section class="profile-area">
    <div class="profile-header mb-30">
        <div class="user-profile-images">
            <img src="assets/images/profilebg.jpg" class="cover-image" alt="image">
            <div class="profile-image">
                <img [src]="profileImage" alt="image">
                <div class="upload-profile-photo">
                    <a href="#"><i class='bx bx-camera'></i> <span>Update</span></a>
                </div>
            </div>
            <div class="user-profile-text">
                <h3>
                    {{ profile.lastName }} {{profile.firstName}}
                </h3>
                <span class="d-block">{{ profile.role }}</span>
            </div>
        </div>
        <div class="user-profile-nav">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    {{ profile.phone }}
                    <!-- <a class="nav-link active" id="changepassword-tab" data-toggle="tab" href="#changepassword"
                        role="tab" aria-controls="changepassword" aria-selected="true">
                        Change Password
                    </a> -->
                </li>
                <li class="nav-item">
                    {{ profile.email }}
                    <!-- <a class="nav-link" id="settings-tab" data-toggle="tab" href="#settings" role="tab"
                        aria-controls="settings" aria-selected="false">
                        Account Settings
                    </a> -->
                </li>
            </ul>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-lg-4 col-md-4">
            <div class="card user-info-box mb-30">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h3>Profile Information</h3>
                </div>
                <div class="card-body">
                    <ul class="list-unstyled mb-0">
                        <li class="d-flex">
                            <i class="bx bx-user mr-2"></i>
                            <span class="d-inline-block">
                                {{ profile.lastName }} {{ profile.firstName }}
                            </span>
                        </li>
                        <li class="d-flex">
                            <i class='bx bx-phone mr-2'></i>
                            <span class="d-inline-block">
                                {{ profile.phone }}
                            </span>
                        </li>
                        <li class="d-flex">
                            <i class='bx bxs-user-detail mr-2'></i>
                            <span class="d-inline-block">
                                {{ profile.gender }}
                            </span>
                        </li>
                        <li class="d-flex">
                            <i class='bx bx-label mr-2'></i>
                            <span class="d-inline-block">{{ profile.role }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-lg-8 col-md-8">
            <div class="tab-content">
                <div class="tab-pane fade show active" id="changepassword" role="tabpanel"
                    aria-labelledby="changepassword-tab">
                    <div class="post-share-content mb-30">
                        <div class="post-share-field">

                            <h4>{{ password_change_info.title }}</h4>
                            <p>{{ password_change_info.subtitle }}</p>

                            <div class="inner-page">
                                <form (ngSubmit)="changePassword()" name="changePasswordForm"
                                    [formGroup]="changePasswordForm">
                                    <div class="row">
                                        <div class="col-lg-5 col-md-5">
                                            <div class="form-holder d-none ml-auto d-md-block">
                                                <label><i class='bx bx-lock'></i></label>
                                                <input [type]="hide ? 'password' : 'text'" class="form-control"
                                                    name="Password" placeholder="Password" formControlName="password"
                                                    #password required autocomplete="false">
                                                <div class="invalid-feedback"
                                                    *ngIf="!changePasswordForm.controls['password'].valid">
                                                    Password is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-md-5">
                                            <div class="form-holder d-none ml-auto d-md-block">
                                                <label><i class='bx bx-lock'></i></label>
                                                <input [type]="hide ? 'password' : 'text'" class="form-control"
                                                    placeholder="Password confirmation"
                                                    formControlName="password_confirmation" #password_confirmation
                                                    required autocomplete="false">
                                                <div class="invalid-feedback"
                                                    *ngIf="!changePasswordForm.controls['password_confirmation'].valid">
                                                    Password confirmation is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="clickable icon-35" (click)="hide = !hide">
                                                <i *ngIf="!hide" class="bx bx-show"></i>
                                                <i *ngIf="hide" class="bx bx-hide"></i>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div class="post-share-footer d-flex align-items-center justify-content-between mt-4 pt-4">
                            <div class="post-share-info">

                            </div>
                            <div class="post-btn">
                                <button type="button" class="app-btn" [disabled]="isLoading" (click)="changePassword()">
                                    <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-20 btn-icon"
                                        role="status" aria-hidden="true"></span>
                                    <span *ngIf="isLoading">Processing</span>
                                    <span *ngIf="!isLoading">
                                        <i class="bx bx-save mr-20 btn-icon icon-20"></i>
                                        Save Changes
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                    <div class="post-share-content mb-30">
                        <div class="post-share-field">

                            <h5>App Notifications Settings</h5>
                            <div class="alert alert-danger rounded-pill mt-20" role="alert" *ngIf="hasError">
                                <p class="bold">{{ errorData.message }}</p>
                                <ul>
                                    <li *ngFor="let item of errorData.data.error.errors | keyvalue">
                                        <b>{{ item.key }}: </b> {{ item.value}}
                                    </li>
                                </ul>
                            </div>
                            <div class="alert alert-success rounded-pill" role="alert" *ngIf="isSuccess">{{ msg
                                }}</div>
                            <form (ngSubmit)="changeSettings()" name="signInForm" [formGroup]="settingsForm">
                            </form>

                        </div>
                        <div class="post-share-footer d-flex align-items-center justify-content-between mt-4 pt-4">
                            <div class="post-share-info">

                            </div>
                            <div class="post-btn">
                                <button type="button" class="app-btn" [disabled]="isLoading" (click)="changeSettings()">
                                    <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-10" role="status"
                                        aria-hidden="true"></span>
                                    <span *ngIf="isLoading">Processing</span>
                                    <span *ngIf="!isLoading"><i class="bx bx-save"></i> Save changes</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</section>
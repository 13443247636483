<app-breadcrumb [title]="title" [icon]="icon" [crumbs]="crumbs"></app-breadcrumb>

<div class="row">
    <div class="col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Registered Application Users</h3>
                <div class="form-area">
                    <div class="form-field app-shadow">
                        <i class="bx bx-filter"></i>
                        <input type="text" placeholder="Type to filter" matInput (keyup)="applyFilter($event)" #input>
                    </div>

                </div>
                <div style="width: 220px;">
                    <button type="button" class="app-toggle-btn" (click)="openDialog()">
                        <i class="bx bx-user-plus mr-10 btn-icon"></i>
                        {{ addUserTitle }}
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <table mat-table class="full-width-table" matSort aria-label="Elements">
                            <!-- imageUrl Column -->
                            <ng-container matColumnDef="imageUrl">
                                <th class="text-uppercase" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Picture
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <span class="circle with-shadow">
                                        <img [src]="row.imageUrl" alt="DP">
                                    </span>
                                </td>
                            </ng-container>

                            <!-- lastName Column -->
                            <ng-container matColumnDef="lastName">
                                <th class="text-uppercase" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Last Name
                                </th>
                                <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
                            </ng-container>

                            <!-- firstName Column -->
                            <ng-container matColumnDef="firstName">
                                <th class="text-uppercase" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    First Name
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    {{ row.firstName }}
                                </td>
                            </ng-container>

                            <!-- email Column -->
                            <ng-container matColumnDef="email">
                                <th class="text-uppercase" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Email Address
                                </th>
                                <td class="no-color" mat-cell *matCellDef="let row">
                                    <a href="mailto:{{row.email}}">{{ row.email }}</a>
                                </td>
                            </ng-container>

                            <!-- phone Column -->
                            <ng-container matColumnDef="phone">
                                <th class="text-uppercase" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Phone Number
                                </th>
                                <td class="no-color" mat-cell *matCellDef="let row">
                                    <a href="tel:{{row.phone}}">{{ row.phone }}</a>
                                </td>
                            </ng-container>

                            <!-- role Column -->
                            <ng-container matColumnDef="role">
                                <th class="text-uppercase" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    role
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    {{ row.role }}
                                </td>
                            </ng-container>

                            <!-- transactionLimit Column -->
                            <ng-container matColumnDef="id">
                                <th class="text-uppercase" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    ACTIONS
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <button type="button" class="app-small-btn blue-grey mt-1">
                                        <i class="bx bx-edit-alt white-text mr-1"></i>
                                        Update
                                    </button> <br>
                                    <button type="button" class="app-small-btn orange mt-1">
                                        <i class="bx bx-lock-alt white-text mr-1"></i>
                                        Suspend
                                    </button><br>
                                    <button type="button" class="app-small-btn mt-1 mb-2" (click)="viewUserDialog(row)">
                                        <i class="bx bx-lock-alt  mr-1"></i>
                                        View user
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>

                        <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
                            [pageSizeOptions]="[5, 10, 25, 50, 100, 250]">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
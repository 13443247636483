export class HttpApiEndpoint {
    // OAuth
    static oauthLogin = 'oauth/token';
    // Authentication
    static userSignIn = 'user/login'
    static userSignUp = 'user/register'
    static userChangePassword = 'change-password'
    static userSignOut = 'sign-out'
    static userData = 'user/fetch'
    static usersData = 'user/get/all'
    static reauthenticateUser = 're-authenticate-user '
}
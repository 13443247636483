import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { DispatchRequestService } from 'src/app/services/dispatch-request.service';

@Component({
  selector: 'app-update-price',
  templateUrl: './update-price.component.html',
  styleUrls: ['./update-price.component.css']
})
export class UpdatePriceComponent implements OnInit {

  isLoading: boolean = false
  uid: string
  amount: number = 0
  amountEntered: number = 0

  constructor(private formBuilder: FormBuilder, private matDialog: MatDialog, public dialogRef: MatDialogRef<UpdatePriceComponent>, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService, private requestService: DispatchRequestService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.uid = _localStorageService.get('uid')
  }

  ngOnInit(): void {
  }

  modelChange(amnt: number): void {
    this.amountEntered = amnt;
  }

  form = this.formBuilder.group({
    amount: ['', { validators: [Validators.required], updateOn: "change" }],
  })

  async onSubmit() {
    if (this.form.invalid) {
      this.toastr.error('Provide an amount', 'Request Failed')
      return
    }

    this.isLoading = true

    this.amount = this.form.value.amount
    await this.requestService.updateRequestPrice(this.data.id, this.data.payment.estimatedCost, this.amount, this.data.uid, this.data.item.title, this.data.addedOn,).then(res => {
      this.isLoading = false
      this.dialogRef.close()
      this.toastr.success('Request amount updated successfully and User has been notified.', 'Request Successful')
    }).catch(err => {
      console.log(err)
      this.toastr.success(err, 'Request Failed to add to FS')
    })
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultComponent } from './default.component';
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { Ng5SliderModule } from 'ng5-slider';
import { ChatComponent } from 'src/app/modules/chat/chat.component';
import { DispatchRequestsComponent } from 'src/app/modules/dispatch-requests/dispatch-requests.component';
import { RidersComponent } from 'src/app/modules/riders/riders.component';
import { SettingsComponent } from 'src/app/modules/settings/settings.component';
import { SupportComponent } from 'src/app/modules/support/support.component';
import { TrackerComponent } from 'src/app/modules/tracker/tracker.component';
import { TripsComponent } from 'src/app/modules/trips/trips.component';
import { WalletsComponent } from 'src/app/modules/wallets/wallets.component';
import { ProfileComponent } from 'src/app/modules/profile/profile.component';
import { NotificationsComponent } from 'src/app/modules/notifications/notifications.component';
import { CalendarComponent } from 'src/app/modules/calendar/calendar.component';
import { TasksComponent } from 'src/app/modules/tasks/tasks.component';


// Angula Material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { NewUserComponent } from 'src/app/modules/diaog/new-user/new-user.component';
import { ViewUserComponent } from 'src/app/modules/dialog/view-user/view-user.component';
import { RequestComponent } from 'src/app/modules/dispatch-requests/request/request.component';
import { NgPipesModule } from 'ngx-pipes';
import { ViewTableCellComponent } from 'src/app/modules/dialog/view-table-cell/view-table-cell.component';
import { UpdatePriceComponent } from 'src/app/modules/dialog/update-price/update-price.component';
import { PaymentsComponent } from 'src/app/modules/wallets/payments/payments.component';
import { TrackingComponent } from 'src/app/modules/tracker/tracking/tracking.component';
import { NewRiderComponent } from 'src/app/modules/dialog/new-rider/new-rider.component';
import { TrackRiderComponent } from 'src/app/modules/riders/track-rider/track-rider.component';

@NgModule({
  declarations: [
    DefaultComponent,
    DashboardComponent,
    ChatComponent,
    DispatchRequestsComponent,
    RidersComponent,
    SettingsComponent,
    SupportComponent,
    TrackerComponent,
    TripsComponent,
    WalletsComponent,
    ProfileComponent,
    NotificationsComponent,
    ChatComponent,
    CalendarComponent,
    TasksComponent,
    NewUserComponent,
    ViewUserComponent,
    RequestComponent,
    ViewTableCellComponent,
    UpdatePriceComponent,
    PaymentsComponent,
    TrackingComponent,
    NewRiderComponent,
    TrackRiderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    Ng5SliderModule,
    MatPaginatorModule,
    NgPipesModule,
    AutocompleteLibModule,

    // MatAutocompleteModule,
    // MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    // MatCheckboxModule,
    // MatChipsModule,
    // MatDatepickerModule,
    // MatDialogModule,
    // MatExpansionModule,
    // MatGridListModule,
    // MatIconModule,
    // MatSidenavModule,
    MatTableModule,
    // MatTabsModule,
    // MatToolbarModule,
    // MatTooltipModule,
    MatSelectModule,
    // MatListModule,
    // MatPaginatorModule,
    // MatSortModule,
    // MatSnackBarModule,
    // MatStepperModule,
    // MatSlideToggleModule,
    // MatSliderModule,
    // MatMenuModule,
    // MatRadioModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    MatInputModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    Ng5SliderModule,
    MatPaginatorModule,
    NgPipesModule,

    // MatAutocompleteModule,
    // MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    // MatCheckboxModule,
    // MatChipsModule,
    // MatDatepickerModule,
    // MatDialogModule,
    // MatExpansionModule,
    // MatGridListModule,
    // MatIconModule,
    // MatSidenavModule,
    MatTableModule,
    // MatTabsModule,
    // MatToolbarModule,
    // MatTooltipModule,
    MatSelectModule,
    // MatListModule,
    // MatPaginatorModule,
    // MatSortModule,
    // MatSnackBarModule,
    // MatStepperModule,
    // MatSlideToggleModule,
    // MatSliderModule,
    // MatMenuModule,
    // MatRadioModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    MatInputModule,
  ]
})
export class DefaultModule { }

<app-breadcrumb [title]="title" [icon]="icon" [crumbs]="crumbs"></app-breadcrumb>

<div class="row">
    <div class="col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Click Map Pin To View Current Position And Speed</h3>

            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">

                        <div #map style="width: 100%; height: 550px"></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
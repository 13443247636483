<div class="auth">
    <div class="auth-slide" [ngStyle]="{'background-image': bgImage}">
        <div class="auth-slide-heading">
            <h1>Welcome to Express Delivery<span class="primary-text">!</span></h1>
            <p>We pride ourselves in how FAST we can deliver your packages.</p>
        </div>
    </div>
    <div class="auth-form-area">
        <router-outlet></router-outlet>
    </div>
</div>
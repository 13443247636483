<h4 mat-dialog-title>Create New Rider Account</h4>
<hr>
<div class="row" style="width: 710px">
    <div class="col-md-12">

        <div class="form-area">

            <div class="ng-autocomplete">
                <ng-autocomplete [data]="data" [searchKeyword]="keyword"
                    placeholder="Choose your prefered user to convert to rider" required
                    (selected)="selectUserEvent($event)" historyIdentifier="data" [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate">
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.firstName"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                </ng-template>
            </div>

        </div>

        <div class="row">
            <div class="col-md-4">
                <img [src]="selectedUser.imageUrl" alt="D">
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-4">Last Name</div>
                    <div class="col-md-8 text-bold">{{ selectedUser.lastName }}</div>
                </div>
                <div class="row">
                    <div class="col-md-4">First Name</div>
                    <div class="col-md-8 text-bold">{{ selectedUser.firstName }}</div>
                </div>
                <div class="row">
                    <div class="col-md-4">Phone</div>
                    <div class="col-md-8 text-bold">{{ selectedUser.phone }}</div>
                </div>
                <div class="row">
                    <div class="col-md-4">Email</div>
                    <div class="col-md-8 text-bold">{{ selectedUser.email }}</div>
                </div>
                <div class="row">
                    <div class="col-md-4">Status</div>
                    <div class="col-md-8 text-bold">
                        <span *ngIf="selectedUser.accountStatus == 1">Active</span>
                        <span *ngIf="selectedUser.accountStatus != 1">Inactive</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">Account Type</div>
                    <div class="col-md-8 text-bold">{{ selectedUser.role }}</div>
                </div>
            </div>
        </div>

        <button class="app-btn" (click)="onSubmit()" *ngIf="canProceed">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-10" role="status"
                aria-hidden="true"></span>
            <span *ngIf="isLoading">Processing</span>
            <span *ngIf="!isLoading">Convert {{ selectedUser.firstName }} to rider</span>
        </button>
    </div>
</div>
<h4 mat-dialog-title>Credit {{ rider.name }}'s Bank Account</h4>
<hr>
<div class="row" style="width: 710px">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12 orange-text">
                Ensure you have successfully credited the rider's bank account before taking the following steps.
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="teal-text text-bold">
                            <small class="isSlim" [innerHtml]="naira"></small>
                            {{ riderWalletBalance | number: '1.2-2' }}<br>
                            <small class="isSlim">Wallet Balance</small>
                        </h1>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <form (ngSubmit)="onSubmit()" name="openAccountForm" [formGroup]="form">
                    <div class="form-area">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-field app-shadow">
                                    <i class="bx bx-money"></i>
                                    <input type="number" placeholder="Enter Amount Here" formControlName="amount"
                                        #amount (ngModelChange)="modelChange($event)">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <h2>N{{ amountEntered | number}}</h2>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <button class="app-btn" (click)="onSubmit()">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-10" role="status"
                aria-hidden="true"></span>
            <span *ngIf="isLoading">Processing</span>
            <span *ngIf="!isLoading">Save Changes</span>
        </button>
    </div>
</div>
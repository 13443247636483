<h4 mat-dialog-title>Create New User Account</h4>
<hr>
<div class="row" style="width: 600px">
    <div class="col-md-12">
        <form (ngSubmit)="onSubmit()" name="openAccountForm" [formGroup]="form">
            <div class="form-area">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-field app-shadow">
                            <i class="bx bx-user"></i>
                            <input type="text" placeholder="Last Name" formControlName="lastName" #lastName>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-field app-shadow">
                            <i class="bx bx-user"></i>
                            <input type="text" placeholder="First Name" formControlName="firstName" #firstName>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <div class="form-field app-shadow">
                            <i class="bx bx-phone"></i>
                            <input type="text" placeholder="Phone Number" formControlName="phoneNumber" #phoneNumber>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-field app-shadow">
                            <i class="bx bx-user"></i>
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Gender</mat-label>
                                <mat-select formControlName="gender">
                                    <mat-option *ngFor="let gender of genders" [value]="gender.value">
                                        {{ gender.viewValue }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="!form.controls['gender'].valid">
                                    Kindly provide your gender
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-12">
                        <div class="form-field app-shadow">
                            <i class="bx bx-lock"></i>
                            <input type="email" placeholder="Email Address" formControlName="email" #email>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6">
                        <small>Password</small>
                        <div class="form-field app-shadow">
                            <i class="bx bx-lock"></i>
                            <input type="password" formControlName="password" #password>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <small>Confirm Password</small>
                        <div class="form-field app-shadow">
                            <i class="bx bx-lock"></i>
                            <input type="password" formControlName="confirmPassword" #confirmPassword>
                        </div>
                    </div>
                </div>

            </div>

            <button class="app-btn" (click)="onSubmit()">
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-10" role="status"
                    aria-hidden="true"></span>
                <span *ngIf="isLoading">Processing</span>
                <span *ngIf="!isLoading">Create Account</span>
            </button>
        </form>
    </div>
</div>
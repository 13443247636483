<app-breadcrumb [title]="title" [icon]="icon" [crumbs]="crumbs"></app-breadcrumb>

<div class="row">
    <div class="col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <!-- <p>Search by entering Airway Bill Number in provided box: <br> </p> -->

                <div class="form-holder">
                    <form class="form-inline" (ngSubmit)="onTrack()" [formGroup]="trackForm">
                        <input type="text" id="userId" required placeholder="User ID*" #userId formControlName="userId">
                        <input type="text" id="abn" required placeholder="Airway Bill Number*" #abn
                            formControlName="abn">
                        <button (click)="onTrack()">
                            <span *ngIf="isLoading">
                                <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-10" role="status"
                                    aria-hidden="true"></span>
                                &nbsp; Tracking
                            </span>
                            <span *ngIf="!isLoading">
                                <i class='bx bx-map-pin white-text'></i> &nbsp;
                                Start Tracking
                            </span>
                        </button>
                    </form>
                </div>

            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <span *ngIf="fetched">
                            <section class="root">
                                <figure>
                                    <span>
                                        <img [src]="dispatchRequest.item.imageUrl">
                                    </span>
                                    <figcaption>
                                        <h4>Tracking Details</h4>
                                        <h6>Airway Bill Number</h6>
                                        <h2>{{ airwayBillNumber }}</h2>
                                    </figcaption>
                                </figure>
                                <div class="order-track">

                                    <div class="order-track-step" *ngFor="let tracker of trackers">
                                        <div class="order-track-status">
                                            <span class="order-track-status-dot"></span>
                                            <span class="order-track-status-line"></span>
                                        </div>
                                        <div class="order-track-text">
                                            <p class="order-track-text-stat">
                                                {{ tracker.title}}
                                            </p>
                                            <span class="order-track-text-sub">
                                                {{ tracker.currentLocation }}
                                            </span>
                                            <br>
                                            <span class="order-track-text-sub">
                                                {{ tracker.addedOn | date:'yyyy/MM/dd h:mm:ss a' }}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </section>
                        </span>
                    </div>
                    <div class="col-md-6">
                        <span *ngIf="fetched">
                            <span class="isSlim">Category:</span>
                            {{ dispatchRequest.item.itemType }}
                        </span>

                        <div style="padding-bottom: 6px ;">
                            <div style="font-size: 21px ;text-transform: none ;">
                                <p>
                                    <span *ngIf="fetched">
                                        <small class="isSlim">Delivery Mode: </small>
                                        {{ dispatchRequest.item.transportation }}
                                    </span>
                                </p>
                                <p>
                                    <span *ngIf="fetched">
                                        <small class="isSlim">Item Valued At: </small>
                                        ₦{{ dispatchRequest.item.valuedAt | number }}
                                    </span>
                                </p>
                                <p>
                                    <span *ngIf="fetched">
                                        <small class="isSlim">Is Item Fragile?: </small>
                                        <span *ngIf="dispatchRequest.item.isFragile">
                                            This package is fragile, handle with care.
                                        </span>
                                        <span *ngIf="!dispatchRequest.item.isFragile">This item is not
                                            fragile.</span>
                                    </span>
                                </p>
                                <p>
                                    <span *ngIf="fetched">
                                        <small class="isSlim">Item Weight: </small>
                                        {{ dispatchRequest.item.weight }} kG
                                    </span>
                                </p>
                            </div>

                            <div style="font-size: 17px; text-transform: none ;">
                                <p>
                                    <span *ngIf="fetched">
                                        <small class="isSlim">Estimated Distance: </small>
                                        {{ dispatchRequest.location.distance }}
                                    </span>
                                </p>
                                <p>
                                    <span *ngIf="fetched">
                                        <small class="isSlim">Estimated Duration: </small>
                                        {{ dispatchRequest.location.duration }}
                                    </span>
                                </p>
                                <p>
                                    <span *ngIf="fetched">
                                        <small class="isSmall">Item picked up at: <br></small>
                                        {{ dispatchRequest.location.pickupAddress }}
                                    </span>
                                </p>
                                <p>
                                    <span *ngIf="fetched">
                                        <small class="isSmall">Recipient address: <br></small>
                                        {{ dispatchRequest.location.recipientAddress }}
                                    </span>
                                </p>
                                <p>
                                    <span *ngIf="fetched">
                                        <small class="isSmall">Item Description: <br></small>
                                        {{ dispatchRequest.item.description }}
                                    </span>
                                </p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
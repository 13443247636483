<app-sidenav></app-sidenav>

<div class="main-content d-flex flex-column">

    <app-topnav></app-topnav>

    <router-outlet></router-outlet>

    <div class="flex-grow-1"></div>

    <app-footernav></app-footernav>

</div>
import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { Profile } from 'src/app/interfaces/profile';
import { SettingsService } from 'src/app/services/settings.service';
import { Theme } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent implements OnInit {

  title = 'Chat'
  theme: Theme = 'light'
  isDark: boolean = false
  profile: any = {}
  token: string = ""
  year = new Date().getFullYear()
  age: number = 0
  dob: any = ""
  uid: string

  _limit: number = 20;
  _limitIncrement: number = 20;
  isLoading: boolean = false;

  users: any

  constructor(private titleService: Title, private formBuilder: FormBuilder, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService, private router: Router, private settingsService: SettingsService) {
    this.titleService.setTitle(this.title);

    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
    // Get user profile and token
    this.profile = _localStorageService.get('upd')
    this.token = _localStorageService.get('token')
    this.uid = _localStorageService.get('uid')
  }

  ngOnInit(): void {
    this.fetchUsers()
    console.log('USERS:: ', this.users)
  }

  // Fetch All Users
  fetchUsers() {
    this.users = []
    this.settingsService.getAllUsers().subscribe(res => {
      let u: any = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        u.push(item as Profile)
      })
      console.log('U::: ', u)
      this.users = u
      console.log('USERSSSS::: ', this.users)
    })
  }

}

<app-breadcrumb [title]="title" [icon]="icon" [crumbs]="crumbs"></app-breadcrumb>

<div class="row">
    <div class="col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Search by entering Airway Bill Number in provided box: </h3>

                <form (ngSubmit)="trackItem()" name="form" [formGroup]="form">
                    <div class="form-area">
                        <div class="form-field app-shadow">
                            <i class="bx bx-filter ml-6"></i>
                            <input type="text" placeholder="Choose Airway Bill No" formControlName="airwayBillNumber"
                                #airwayBillNumber>
                            <button class="track-btn" type="button" (click)="trackItem()">
                                <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-10" role="status"
                                    aria-hidden="true"></span>
                                <span *ngIf="isLoading">Tracking</span>
                                <span *ngIf="!isLoading">Track</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-body">
                data here
            </div>
        </div>
    </div>
</div>
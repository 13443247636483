import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  bgImage = 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("assets/images/corpwoman1.jpg")'

  bgImages = [
    { id: 1, image: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("assets/images/corpwoman.jpg")' },
    { id: 2, image: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("assets/images/corpman.jpg")' },
    { id: 3, image: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("assets/images/corpwoman1.jpg")' },
    { id: 4, image: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("assets/images/corpwoman2.jpg")' },
  ]

  dataRefresher: any

  counter: number = 0

  constructor() { }

  ngOnInit(): void {
    this.refreshData()
  }

  // Fetch bg every 3 seconds
  refreshData() {
    this.dataRefresher = setInterval(() => {
      this.counter++

      // loop through images
      this.bgImages.forEach((i: any) => {
        // compare id's and assign omage to bg image
        if (this.counter === i.id) {
          this.bgImage = i.image
        }
      })

      if (this.counter > 4) {
        this.counter = 0
      }
    }, 3000)
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Profile } from '../interfaces/profile';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  profile: any
  profileToUpdate: any

  constructor(private db: AngularFirestore) { }

  profileCollection = this.db.collection('users')
  profileCollectionName = 'users'

  private itemDoc: AngularFirestoreDocument<Profile>;
  item: any;

  // Create New User
  createNewUser(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.profileCollection.doc(data.id).set(data).then(res => resolve(res), err => reject(err))
    })
  }

  // Get all users
  getAllUsers() {
    return this.profileCollection.snapshotChanges();
  }

  // Get single user
  getUser(uid: string): Observable<Profile> {
    const userDocument = this.db.doc<Profile>('users/' + uid);
    return userDocument.snapshotChanges()
      .pipe(
        map(changes => {
          const data = changes.payload.data();
          const id = changes.payload.id;
          return { id, ...data };
        }))
  }

  // Update user
  updateUser(id: any, data: any) {
    return new Promise<any>((resolve, reject) => {
      this.db.doc(this.profileCollectionName + '/' + id).update(data).then(res => resolve(res), err => reject(err))
    })
  }

}

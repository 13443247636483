import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../interfaces/user';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { environment } from 'src/environments/environment';
import { HttpApiEndpoint } from '../interceptors/http-api-endpoints';
import { map } from 'rxjs/operators';

const API_URL = environment.REST_API_SERVER;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, _localStorageService: LocalStorageServiceEncrypt) { }

  /**
   * Change user password with email
   * @param password 
   * @param passwordConfirmation 
   * @returns user data
   */
  changeUserPassword(id: number, password: string, passwordConfirmation: string, token: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer ${token}`);

    var data = {
      id: id,
      password: password,
      passwordConfirmation: passwordConfirmation
    }

    return this.http.post(`${HttpApiEndpoint.userChangePassword}`, data, { headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**
   * Sign user out
   */
  signOut(token: string): Observable<User> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer-${token}`);

    return this.http.post<User>(`${HttpApiEndpoint.userSignOut}`, { headers }).pipe(
      map((response: any) => {
        return response
      })
    )
  }

  // resetUserPassword(email: string) { }

  /**
   * Sign User in with email and password
   * @param email 
   * @param password 
   * @returns user data
   */
  signInWithUserCredentials(email: string, password: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    var data = {
      email: email,
      password: password
    }

    return this.http.post(`${HttpApiEndpoint.userSignIn}`, data, { headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**
  * Sign User in with email and password
  * @param email 
  * @param password 
  * @param phoneNumber
  * @param confirmPassword
  * @param firstName
  * @param lastName
  * @returns user data
  */
  signUpWithUserCredentials(lastName: string,
    firstName: string,
    phoneNumber: string,
    email: string,
    password: string,
    confirmPassword: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    var data = {
      lastName: lastName,
      firstName: firstName,
      phoneNumber: phoneNumber,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
    }

    return this.http.post(`${HttpApiEndpoint.userSignUp}`, data, { headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  logout(): void {
    localStorage.clear();
  }

  isLogged(): boolean {
    return localStorage.getItem('session') ? true : false;
  }

  // get accessToken() {
  //   return localStorage.session ? JSON.parse(localStorage.session).access_token : null;
  // }

  // get refreshToken() {
  //   return localStorage.session ? JSON.parse(localStorage.session).refresh_token : null;
  // }

}

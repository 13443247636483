import { Component, OnInit } from '@angular/core';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { Theme } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-footernav',
  templateUrl: './footernav.component.html',
  styleUrls: ['./footernav.component.css']
})
export class FooternavComponent implements OnInit {

  theme: Theme = 'light'
  isDark: boolean = false
  year = new Date().getFullYear()

  constructor(private _localStorageService: LocalStorageServiceEncrypt) {
    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
  }

  ngOnInit(): void {
  }

}
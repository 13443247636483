import { Component } from '@angular/core';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Express Delivery';
  isSticky = 'is-sticky'

  constructor() { }

  ngOnInit(): void {

    // jQuery(window).on("load", function () {
    //   jQuery(".page-loader").delay(500).fadeOut("slow");
    //   jQuery("body").delay(450).css({
    //     overflow: "visible",
    //   });
    // })

    jQuery(window).on("load", function () {
      jQuery("#preloader-active").delay(500).fadeOut("slow");
      jQuery("body").delay(500).css({
        overflow: "visible",
      });
    });

    // Sticky header controller
    var sticky = this.isSticky;
    jQuery(window).on("scroll", function () {
      var isSticky = sticky;
      var scroll = jQuery(window).scrollTop()
      if (scroll) {
        if (scroll >= 30) {
          jQuery('.top-navbar').addClass(isSticky);
        } else {
          jQuery('.top-navbar').removeClass(isSticky);
        }
      }
    })

  }
}

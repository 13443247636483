import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './layouts/auth/auth.component';
import { DefaultComponent } from './layouts/default/default.component';
import { CalendarComponent } from './modules/calendar/calendar.component';
import { ChatComponent } from './modules/chat/chat.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { DispatchRequestsComponent } from './modules/dispatch-requests/dispatch-requests.component';
import { RequestComponent } from './modules/dispatch-requests/request/request.component';
import { ForgotpasswordComponent } from './modules/forgotpassword/forgotpassword.component';
import { NotificationsComponent } from './modules/notifications/notifications.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { RidersComponent } from './modules/riders/riders.component';
import { TrackRiderComponent } from './modules/riders/track-rider/track-rider.component';
import { SettingsComponent } from './modules/settings/settings.component';
import { SigninComponent } from './modules/signin/signin.component';
import { SignupComponent } from './modules/signup/signup.component';
import { SupportComponent } from './modules/support/support.component';
import { TasksComponent } from './modules/tasks/tasks.component';
import { TrackerComponent } from './modules/tracker/tracker.component';
import { TrackingComponent } from './modules/tracker/tracking/tracking.component';
import { TripsComponent } from './modules/trips/trips.component';
import { PaymentsComponent } from './modules/wallets/payments/payments.component';
import { WalletsComponent } from './modules/wallets/wallets.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: DashboardComponent,
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: DashboardComponent,
        data: { title: 'Dashboard' }
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        component: ProfileComponent,
        data: { title: 'Profile' }
      },
      {
        path: 'chats',
        canActivate: [AuthGuard],
        component: ChatComponent,
        data: { title: 'Chats' }
      },
      {
        path: 'notifications',
        canActivate: [AuthGuard],
        component: NotificationsComponent,
        data: { title: 'Notifications' }
      },
      {
        path: 'dispatch/requests',
        canActivate: [AuthGuard],
        component: DispatchRequestsComponent,
        data: { title: 'Dispatch Requests' }
      },
      {
        path: 'dispatch/request',
        canActivate: [AuthGuard],
        component: RequestComponent,
        data: { title: 'Dispatch Request' }
      },
      {
        path: 'riders',
        canActivate: [AuthGuard],
        component: RidersComponent,
        data: { title: 'Riders' }
      },
      {
        path: 'riders/rider/track',
        canActivate: [AuthGuard],
        component: TrackRiderComponent,
        data: { title: 'Track Rider' }
      },
      {
        path: 'app/settings',
        canActivate: [AuthGuard],
        component: SettingsComponent,
        data: { title: 'Settings' }
      },
      {
        path: 'app/support',
        canActivate: [AuthGuard],
        component: SupportComponent,
        data: { title: 'Support' }
      },
      {
        path: 'dispatch/tracker',
        canActivate: [AuthGuard],
        component: TrackerComponent,
        data: { title: 'Dispatch Tracker' }
      },
      {
        path: 'dispatch/tracker/tracking',
        canActivate: [AuthGuard],
        component: TrackingComponent,
        data: { title: 'Tracking' }
      },
      {
        path: 'wallets',
        canActivate: [AuthGuard],
        component: WalletsComponent,
        data: { title: 'Wallets' }
      },
      {
        path: 'wallet/payments',
        canActivate: [AuthGuard],
        component: PaymentsComponent,
        data: { title: 'Wallet Payments' }
      },
      {
        path: 'tasks',
        canActivate: [AuthGuard],
        component: TasksComponent,
        data: { title: 'Tasks' }
      },
      {
        path: 'calendar',
        canActivate: [AuthGuard],
        component: CalendarComponent,
        data: { title: 'Calendar' }
      },
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'signin',
        component: SigninComponent,
        data: { title: 'Sign In' }
      },
      {
        path: 'signup',
        component: SignupComponent,
        data: { title: 'Sign Up' }
      },
      {
        path: 'forgot-password',
        component: ForgotpasswordComponent,
        data: { title: 'Forgot Password' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

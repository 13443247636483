import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { Rider } from 'src/app/interfaces/rider';
import { DispatchRequestService } from 'src/app/services/dispatch-request.service';
import { RidersService } from 'src/app/services/riders.service';
import { Theme } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.css']
})
export class TripsComponent implements OnInit {

  theme: Theme = 'light'
  isDark: boolean = false
  profile: any = {}
  token: string = ""
  year = new Date().getFullYear()
  age: number = 0
  dob: any = ""
  uid: string

  title = "Dispatch Riders Trips"
  icon = "bx bx-map-pin"
  crumbs = [
    { title: "Dashboard", icon: "bx bx-home", url: "/dashboard" },
    { title: "Dispatch Riders Trips", icon: "bx bx-map-pin", url: "/dispatch/trips" },
    { title: "Overview", icon: "", url: null },
  ]

  pageTitle = 'Dispatch Riders Trips'

  riders: Rider[] = [];

  // data Table
  ELEMENT_DATA: Rider[] = []
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns: string[] = [
    'imageUrl',
    'name',
    'online',
    'gender',
    'phone',
    'trips',
    'votes',
    'id'
  ];

  // PAss data source
  dataSource = new MatTableDataSource<Rider>(this.ELEMENT_DATA)

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Rider>;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  constructor(private titleService: Title, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService, private dispatchRequestService: DispatchRequestService, public matDialog: MatDialog, private router: Router, private ridersService: RidersService) {
    this.titleService.setTitle(this.pageTitle);

    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
    // Get user profile and token
    this.profile = _localStorageService.get('upd')
    this.token = _localStorageService.get('token')
    this.uid = _localStorageService.get('uid')
  }

  ngOnInit(): void {
    this.fetchUsers()
  }

  // Filter table
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // Open new account dialog
  viewDispatchRequestSubCollections(data: any) {
    const navigationExtras: NavigationExtras = { state: { riderId: data.id } };
    this.router.navigate(['dispatch/tracker/tracking'], navigationExtras);
  }

  // Fetch All Users
  fetchUsers() {
    this.ridersService.getAllRiders().subscribe(res => {
      this.riders = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.riders.push(item as Rider)
      })
      this.dataSource.data = this.riders
    })
  }


}

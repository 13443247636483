<app-breadcrumb [title]="title" [icon]="icon" [crumbs]="crumbs"></app-breadcrumb>

<div class="row">
    <div class="col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Application Developer Contact Information</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <span class="circle with-shadow">
                            <img [src]="developer.imageUrl" alt="DP">
                        </span>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-4">Name</div>
                            <div class="col-md-8 text-bold">{{ developer.name }}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">Phone Number</div>
                            <div class="col-md-8 text-bold">
                                <a href="tel:{{developer.phoneNumber}}">{{ developer.formattedPhoneNumber }}</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">Official Email</div>
                            <div class="col-md-8 text-bold">
                                <a href="mailto:{{developer.email2}}">{{ developer.email2 }}</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">Personal Email</div>
                            <div class="col-md-8 text-bold">
                                <a href="mailto:{{developer.email}}">{{ developer.email }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { DispatchRequest } from 'src/app/interfaces/dispatch-request';
import { Profile } from 'src/app/interfaces/profile';
import { Rider } from 'src/app/interfaces/rider';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DispatchRequestService } from 'src/app/services/dispatch-request.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Theme } from 'src/app/services/theme-toggle.service';
import { UpdatePriceComponent } from '../../dialog/update-price/update-price.component';
import { ViewTableCellComponent } from '../../dialog/view-table-cell/view-table-cell.component';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {

  theme: Theme = 'light'
  isDark: boolean = false
  profile: any = {}
  token: string = ""
  year = new Date().getFullYear()
  age: number = 0
  dob: any = ""
  uid: string

  title = "Dispatch Requests"
  icon = "bx bx-cog"
  crumbs = [
    { title: "Dashboard", icon: "bx bx-home", url: "/dashboard" },
    { title: "Dispatch Requests", icon: "bx bx-home", url: "/dispatch/requests" },
    { title: "User Dispatch Requests", icon: "", url: null },
  ]

  pageTitle = 'Dispatch Requests'

  requestId: any;
  rId: string

  requests: DispatchRequest[] = []

  // data Table
  ELEMENT_DATA: DispatchRequest[] = []
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns: string[] = [
    'addedOn',
    'driverId',
    'item',
    'location',
    'payment',
    'sender',
    'pickupPin',
    'recipient',
    'status'
  ];

  // PAss data source
  dataSource = new MatTableDataSource<DispatchRequest>(this.ELEMENT_DATA)

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<DispatchRequest>;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  lastName: any
  firstName: any
  riders: Rider[] = []

  constructor(private router: Router, private titleService: Title, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService, private dispatchRequestService: DispatchRequestService, public matDialog: MatDialog, private settingsService: SettingsService, private dashboardService: DashboardService) {
    const navigation = this.router.getCurrentNavigation()
    const state = navigation?.extras.state as { data: any }
    this.lastName = state?.data.lastName
    this.firstName = state?.data.firstName
    this.rId = state?.data.id

    // Check if rID exist on localStorage
    if (this.rId != undefined) {
      localStorage.setItem('rID', this.rId)
    }
    this.requestId = localStorage.getItem('rID');

    // Check if user exist on localStorage
    if (this.lastName != undefined || this.firstName != undefined) {
      localStorage.setItem('rLastName', this.lastName)
      localStorage.setItem('rFirstName', this.firstName)
    }
    this.lastName = localStorage.getItem('rLastName');
    this.firstName = localStorage.getItem('rFirstName');
    console.log(this.lastName)
    console.log(this.firstName)

    this.titleService.setTitle(this.pageTitle);

    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
  }

  ngOnInit(): void {
    this.fetchRiders()
    if (this.requestId != null) {
      this.fetchData()
    }
  }

  // Fetch All Requests
  fetchData() {
    this.dispatchRequestService.getAllRequests(this.requestId).subscribe(res => {
      this.requests = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        // let driverName = this.fetchUserData(item.uid)
        this.requests.push(item as DispatchRequest)
      })
      this.dataSource.data = this.requests
    })
  }

  // Filter table
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // view cellt dialog
  viewCellDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(ViewTableCellComponent, { data: data });
  }

  // Update price dialog
  updatePriceDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(UpdatePriceComponent, { disableClose: false, data: data });
  }

  // Fetch All Riders
  fetchRiders() {
    this.dashboardService.getAllRiders().subscribe(res => {
      this.riders = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.riders.push(item as Rider)
      })
    })
  }

  // Get user Name
  getRiderFullName(uid: any) {
    var name
    this.riders.forEach(u => {
      if (u.id == uid) {
        name = u.name
      }
    })
    return name
  }

}

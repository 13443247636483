import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Rider } from '../interfaces/rider';

@Injectable({
  providedIn: 'root'
})
export class RidersService {

  constructor(private db: AngularFirestore) { }

  riders = this.db.collection('riders')
  riderWallets = this.db.collection('riderWallets')
  riderCollection = 'riders'

  // Get all request
  getAllRiders() {
    return this.riders.snapshotChanges();
  }

  // Create new rider
  createNewRider(data: any) {
    let d = { balance: 0.1, id: data.id }
    return new Promise<any>((resolve, reject) => {
      this.riders.doc(data.id).set(data).then(res => resolve(res), err => reject(err))
      this.riders.doc(data.id).set(d).then(res => resolve(res), err => reject(err))
    })
  }

  // Get rider
  getRider(uid: string): Observable<Rider> {
    const userDocument = this.db.doc<Rider>('riders/' + uid);
    return userDocument.snapshotChanges()
      .pipe(
        map(changes => {
          const data = changes.payload.data();
          const id = changes.payload.id;
          return { id, ...data };
        }))
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Payment } from '../interfaces/payment';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  private paymentsCollection: AngularFirestoreCollection<Payment>;
  payments: Observable<DocumentChangeAction<Payment>[]>;

  constructor(private db: AngularFirestore) { }

  wallets = this.db.collection('wallets')
  walletCollection = 'wallets'

  paymentCollection = 'payments'

  // Get all request
  getAllWallets() {
    return this.wallets.snapshotChanges();
  }

  // Get all wallet payments
  getPayments(uid: string) {
    this.paymentsCollection = this.db.collection<Payment>(this.paymentCollection, ref => ref.where('uid', '==', uid).orderBy('timestamp'))
    this.payments = this.paymentsCollection.snapshotChanges()
    return this.payments
  }

  // Numbers with comma
  numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // Update rider wallet balance
  updateRiderWallet(payload: any) {
    let data = { balance: payload.balance }
    return new Promise<any>((resolve, reject) => {
      this.db.collection('riderWallets').doc(payload.id).update(data).then(res => resolve(res), err => reject(err))
    })
  }

}

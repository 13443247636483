<div class="sidemenu-area">
    <div class="sidemenu-header">
        <a routerLink="/" class="navbar-brand d-flex align-items-center">
            <img src="assets/images/icon.png" height="40" alt="image">
            <span class="text-uppercase" style="font-size: 15px;">Express<strong class="fw-600">Delivery</strong></span>
        </a>
        <div class="burger-menu d-none d-lg-block">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
        <div class="responsive-burger-menu d-block d-lg-none">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
    </div>
    <div class="sidemenu-body">
        <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>

            <li class="nav-item-title">Personal Space</li>
            <li class="nav-item" routerLinkActive="mm-active" *ngFor="let item of menu | slice:0:2">
                <a [routerLink]="item.url" class="nav-link">
                    <span class="icon"><i [ngClass]='item.icon'></i></span>
                    <span class="menu-title">{{ item.title }}</span>
                    <span *ngIf="item.alerts > 0" class="badge">{{ item.alerts }}</span>
                </a>
            </li>

            <li class="nav-item-title">Client Activities</li>
            <li class="nav-item" routerLinkActive="mm-active" *ngFor="let item of menu | slice:2:7">
                <a [routerLink]="item.url" class="nav-link">
                    <span class="icon"><i [ngClass]='item.icon'></i></span>
                    <span class="menu-title">{{ item.title }}</span>
                    <span *ngIf="item.alerts > 0" class="badge">{{ item.alerts }}</span>
                </a>
            </li>

            <li class="nav-item-title">App Settings</li>
            <li class="nav-item" routerLinkActive="mm-active" *ngFor="let item of menu | slice:7:11">
                <a [routerLink]="item.url" class="nav-link">
                    <span class="icon"><i [ngClass]='item.icon'></i></span>
                    <span class="menu-title">{{ item.title }}</span>
                    <span *ngIf="item.alerts > 0" class="badge">{{ item.alerts }}</span>
                </a>
            </li>

        </ul>
    </div>
</div>
<h4 mat-dialog-title class="text-uppercase">User information</h4>
<hr>
<div class="row" style="width: 700px">
    <div class="col-md-4">
        <img [src]="data.imageUrl" alt="D">
    </div>
    <div class="col-md-8">
        <div class="row">
            <div class="col-md-4">Last Name</div>
            <div class="col-md-8 text-bold">{{ data.lastName }}</div>
        </div>
        <div class="row">
            <div class="col-md-4">First Name</div>
            <div class="col-md-8 text-bold">{{ data.firstName }}</div>
        </div>
        <div class="row">
            <div class="col-md-4">Phone Number</div>
            <div class="col-md-8 text-bold">{{ data.phone }}</div>
        </div>
        <div class="row">
            <div class="col-md-4">Email</div>
            <div class="col-md-8 text-bold">{{ data.email }}</div>
        </div>
        <div class="row">
            <div class="col-md-4">Status</div>
            <div class="col-md-8 text-bold">
                <span *ngIf="data.accountStatus == 1">Active</span>
                <span *ngIf="data.accountStatus != 1">Inactive</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">Account Type</div>
            <div class="col-md-8 text-bold">{{ data.role }}</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-10"></div>
    <div class="col-md-2">
        <button class="app-small-btn" (click)="close()"> close </button>
    </div>
</div>
<div class="auth-form-content">
    <div class="auth-form-heading">
        <span class="logo">
            <a href="/">
                <img src="assets/images/logo.png" alt="Express Delivery" width="250">
            </a>
        </span>
        <h3 class="mt-10 text-uppercase"> {{ info.title }} </h3>
        <p>{{ info.subtitle }}</p>
    </div>
    <div class="pa-20 error-text" *ngIf="hasError">{{ msg }}</div>

    <form (ngSubmit)="onSubmit()" name="form" [formGroup]="form">
        <div class="form-area">
            <div class="form-field app-shadow">
                <i class="bx bx-envelope"></i>
                <input type="email" name="email" placeholder="Email" autocomplete="false" formControlName="email" #email
                    required>
            </div>
            <div class="invalid-feedback" *ngIf="!form.controls['email'].valid">
                Kindly provide a valid email address
            </div>
            <div class="form-field app-shadow">
                <i class="bx bx-lock"></i>
                <input [type]="hide ? 'password' : 'text'" name="password" placeholder="Password" autocomplete="false"
                    formControlName="password" #password required>
                <span class="label-title clickable" (click)="hide = !hide"><i class='bx bx-lock'></i></span>
            </div>
            <div class="invalid-feedback" *ngIf="!form.controls['password'].valid">
                Password is required
            </div>
            <div class="form-btn">
                <button class="app-btn" (click)="onSubmit()">
                    <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-10" role="status"
                        aria-hidden="true"></span>
                    <span *ngIf="isLoading">Authenticating</span>
                    <span *ngIf="!isLoading">Sign In</span>
                </button>
            </div>
            <div id="snackbar-error">Authentication denied due to invalid credentials</div>
            <div id="snackbar-success">You have been signed in.Ensure to sign out once transactions are completed.</div>
            <!-- <span class="auth-form-signup">
                Don’t have an account?
                <a routerLink="/signup">Create One <i class="bx bx-right-arrow-alt fs-25"></i></a>
            </span> -->
        </div>
    </form>
</div>
<div class="chat-content-area mt-20">
    <div class="sidebar-left">
        <div class="sidebar">
            <div class="chat-sidebar-header d-flex align-items-center">
                <form class="form-group position-relative mb-0">
                    <label><i class="bx bx-search"></i></label>
                    <input type="text" class="form-control" placeholder="Search here...">
                </form>
            </div>
            <div class="sidebar-content d-flex chat-sidebar" data-simplebar="init">
                <div class="simplebar-wrapper" style="margin: 0px;">
                    <div class="simplebar-height-auto-observer-wrapper">
                        <div class="simplebar-height-auto-observer"></div>
                    </div>
                    <div class="simplebar-mask">
                        <div class="simplebar-offset" style="right: -16.8px; bottom: 0px;">
                            <div class="simplebar-content-wrapper" style="height: 100%; overflow: hidden scroll;">
                                <div class="simplebar-content" style="padding: 0px;">
                                    <div class="chat-menu">
                                        <label class="d-block list-group-label">Contacts {{ users }}</label>
                                        <ul class="list-group list-group-user list-unstyled mb-0">

                                            <li *ngFor="let user of users">
                                                <div class="d-flex align-items-center">
                                                    <div class="avatar mr-3">
                                                        <img src="assets/img/user4.jpg" class="rounded-circle"
                                                            alt="image" width="50" height="50">
                                                        <span class="status-away"></span>
                                                    </div>
                                                    <div class="user-name">
                                                        <h6 class="font-weight-bold">Mitsue Dove</h6>
                                                        <span class="d-block">New Order</span>
                                                    </div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="simplebar-placeholder" style="width: auto; height: 721px;"></div>
                </div>
                <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                    <div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); display: none;">
                    </div>
                </div>
                <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                    <div class="simplebar-scrollbar"
                        style="height: 213px; transform: translate3d(0px, 0px, 0px); display: block;"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-right">
        <div class="chat-area">
            <div class="chat-list-wrapper">
                <div class="chat-list">
                    <div class="chat-list-header d-flex align-items-center">
                        <div class="header-left d-flex align-items-center mr-3">
                            <div class="avatar mr-3">
                                <img src="assets/img/user2.jpg" class="rounded-circle" alt="image" width="70"
                                    height="70">
                                <span class="status-online"></span>
                            </div>
                            <h6 class="mb-0 font-weight-bold">Ellen Cranford</h6>
                        </div>
                        <div class="header-right text-right w-100">
                            <ul class="list-unstyled mb-0">
                                <li>
                                    <span class="favorite d-block">
                                        <i class="bx bx-star"></i>
                                    </span>
                                </li>
                                <li>
                                    <div class="dropdown">
                                        <button class="dropdown-toggle" type="button" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item d-flex align-items-center" href="#">
                                                <i class="bx bx-pin"></i> Pin to Top
                                            </a>
                                            <a class="dropdown-item d-flex align-items-center" href="#">
                                                <i class="bx bx-trash"></i> Delete Chat
                                            </a>
                                            <a class="dropdown-item d-flex align-items-center" href="#">
                                                <i class="bx bx-block"></i> Block
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="chat-container" data-simplebar="init">
                        <div class="simplebar-wrapper" style="margin: -25px -20px;">
                            <div class="simplebar-height-auto-observer-wrapper">
                                <div class="simplebar-height-auto-observer"></div>
                            </div>
                            <div class="simplebar-mask">
                                <div class="simplebar-offset" style="right: -16.8px; bottom: 0px;">
                                    <div class="simplebar-content-wrapper"
                                        style="height: 100%; overflow: hidden scroll;">
                                        <div class="simplebar-content" style="padding: 25px 20px;">
                                            <div class="chat-content">
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <a href="#" class="d-inline-block">
                                                            <img src="assets/img/user1.jpg" class="rounded-circle"
                                                                alt="image" width="50" height="50">
                                                        </a>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-message">
                                                            <p>👋Hi​! I'm a Fiva. Let me know if you have any questions
                                                                regarding our tool or set up a demo to learn more!</p>
                                                            <span class="time d-block">7:45 AM</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar">
                                                        <a href="#" class="d-inline-block">
                                                            <img src="assets/img/user2.jpg" class="rounded-circle"
                                                                alt="image" width="50" height="50">
                                                        </a>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-message">
                                                            <p>Could you describe EnvyTheme in one sentence?</p>
                                                            <span class="time d-block">7:45 AM</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <a href="#" class="d-inline-block">
                                                            <img src="assets/img/user1.jpg" class="rounded-circle"
                                                                alt="image" width="50" height="50">
                                                        </a>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-message">
                                                            <p><a href="https://envytheme.com/"
                                                                    target="_blank">EnvyTheme.com</a></p>
                                                            <span class="time d-block">7:45 AM</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="badge badge-pill badge-light my-3">Yesterday</div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar">
                                                        <a href="#" class="d-inline-block">
                                                            <img src="assets/img/user2.jpg" class="rounded-circle"
                                                                alt="image" width="50" height="50">
                                                        </a>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-message">
                                                            <p>What makes you different from other learning platforms?
                                                            </p>
                                                            <span class="time d-block">7:45 AM</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <a href="#" class="d-inline-block">
                                                            <img src="assets/img/user1.jpg" class="rounded-circle"
                                                                alt="image" width="50" height="50">
                                                        </a>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-message">
                                                            <p>The best Innovative Chatbot and automations are here to
                                                                expand.</p>
                                                            <span class="time d-block">7:45 AM</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar">
                                                        <a href="#" class="d-inline-block">
                                                            <img src="assets/img/user2.jpg" class="rounded-circle"
                                                                alt="image" width="50" height="50">
                                                        </a>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-message">
                                                            <p>Great, Thank You!❤️</p>
                                                            <span class="time d-block">7:45 AM</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <a href="#" class="d-inline-block">
                                                            <img src="assets/img/user1.jpg" class="rounded-circle"
                                                                alt="image" width="50" height="50">
                                                        </a>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-message">
                                                            <p>👋Hi​! I'm a Fiva.</p>
                                                            <span class="time d-block">7:45 AM</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar">
                                                        <a href="#" class="d-inline-block">
                                                            <img src="assets/img/user2.jpg" class="rounded-circle"
                                                                alt="image" width="50" height="50">
                                                        </a>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-message">
                                                            <p>What makes you different from other learning platforms?
                                                            </p>
                                                            <span class="time d-block">7:45 AM</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="simplebar-placeholder" style="width: auto; height: 742px;"></div>
                        </div>
                        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                            <div class="simplebar-scrollbar"
                                style="transform: translate3d(0px, 0px, 0px); display: none;"></div>
                        </div>
                        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                            <div class="simplebar-scrollbar"
                                style="height: 190px; transform: translate3d(0px, 0px, 0px); display: block;"></div>
                        </div>
                    </div>
                    <div class="chat-list-footer">
                        <form class="d-flex align-items-center">
                            <div class="btn-box d-flex align-items-center mr-3">
                                <button class="emoji-btn d-inline-block mr-2" data-toggle="tooltip" data-placement="top"
                                    title="" type="button" data-original-title="Emoji"><i
                                        class="bx bx-smile"></i></button>
                                <button class="file-attachment-btn d-inline-block" data-toggle="tooltip"
                                    data-placement="top" title="" type="button" data-original-title="File Attachment"><i
                                        class="bx bx-paperclip"></i></button>
                            </div>
                            <input type="text" class="form-control" placeholder="Type your message...">
                            <button type="submit" class="send-btn d-inline-block">Send <i
                                    class="bx bx-paper-plane"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<nav class="navbar top-navbar navbar-expand">
    <div class="collapse navbar-collapse" id="navbarSupportContent">
        <div class="responsive-burger-menu d-block d-lg-none">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
        <ul class="navbar-nav left-nav align-items-center">
            <li class="nav-item" *ngFor="let item of topLeftMenu">
                <a [routerLink]="item.url" class="nav-link" data-toggle="tooltip" data-placement="bottom"
                    [title]="item.title">
                    <i [ngClass]="item.icon"></i>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link bx-fullscreen-btn" id="fullscreen-button">
                    <i class="bx bx-fullscreen"></i>
                </a>
            </li>
            <li class="nav-item dropdown apps-box">
                <a href="#" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class='bx bxs-grid'></i>
                </a>
                <div class="dropdown-menu">
                    <div class="dropdown-header d-flex justify-content-between align-items-center">
                        <span class="title d-inline-block">Web Apps</span>
                        <span class="edit-btn d-inline-block">Edit</span>
                    </div>
                    <div class="dropdown-body">
                        <div class="d-flex flex-wrap align-items-center">
                            <!-- <a href="#" class="dropdown-item">
                                <img src="assets/img/icon-account.png" alt="image">
                                <span class="d-block mb-0">Account</span>
                            </a>
                            <a href="#" class="dropdown-item">
                                <img src="assets/img/icon-google.png" alt="image">
                                <span class="d-block mb-0">Search</span>
                            </a>
                            <a href="#" class="dropdown-item">
                                <img src="assets/img/icon-map.png" alt="image">
                                <span class="d-block mb-0">Maps</span>
                            </a> -->
                        </div>
                    </div>
                    <div class="dropdown-footer">
                        <a href="#" class="dropdown-item">View All</a>
                    </div>
                </div>
            </li>
        </ul>

        <form class="nav-search-form d-none ml-auto d-md-block">
            <label><i class='bx bx-search'></i></label>
            <input type="text" class="form-control" placeholder="Search here...">
        </form>
        <ul class="navbar-nav right-nav align-items-center">
            <li class="nav-item">
                <button type="button" class="app-toggle-btn" (click)="onThemeChange(isDark)">
                    <span *ngIf="isDark"><i class="bx bx-moon moon mr-10 btn-icon"></i>On Light</span>
                    <span *ngIf="!isDark"><i class="bx bx-sun sun mr-10 btn-icon"></i> Off Light</span>
                </button>
            </li>
            <li class="nav-item dropdown profile-nav-item">
                <a href="#" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <div class="menu-profile">
                        <span class="name">
                            {{ profile.firstName }}
                        </span>
                        <span class="app-circular-avatar">
                            <img [src]="profileImage" alt="image"></span>
                    </div>
                </a>
                <div class="dropdown-menu">
                    <div class="dropdown-header d-flex flex-column align-items-center">
                        <div class="figure mb-3">
                            <img [src]="profileImage" alt="image">
                        </div>
                        <div class="info text-center">
                            <span class="name">{{ profile.lastName }} {{ profile.firstName }}</span>
                            <p class="mb-3 email">
                                <a routerLink="/profile" class="__cf_email__">{{ profile.email }}</a>
                            </p>
                        </div>
                    </div>
                    <div class="dropdown-body">
                        <ul class="profile-nav p-0 pt-3">
                            <li class="nav-item" *ngFor="let item of profileMenu">
                                <a [routerLink]="item.url" class="nav-link">
                                    <i [ngClass]='item.icon'></i> <span>{{ item.title }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="dropdown-footer">
                        <ul class="profile-nav">
                            <li class="nav-item">
                                <a routerLink="#" class="nav-link" (click)="signOut()">
                                    <i class='bx bx-log-out'></i> <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</nav>
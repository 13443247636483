import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Trip } from '../interfaces/trip';

@Injectable({
  providedIn: 'root'
})
export class TripService {
  private paymentsCollection: AngularFirestoreCollection<Trip>;
  payments: Observable<DocumentChangeAction<Trip>[]>;

  constructor(private db: AngularFirestore) { }

  trackingsCollection = ('trackings')
  tripCollection = 'trips'

  // Get all request
  getTrips(uid: string) {
    return this.db.collection(this.tripCollection).doc(uid).collection(this.trackingsCollection).snapshotChanges();
  }

  // // Get all wallet payments
  // getPayments(uid: string) {
  //   this.paymentsCollection = this.db.collection<Payment>(this.paymentCollection, ref => ref.where('uid', '==', uid).orderBy('timestamp'))
  //   this.payments = this.paymentsCollection.snapshotChanges()
  //   return this.payments
  // }
}

<app-breadcrumb [title]="title" [icon]="icon" [crumbs]="crumbs"></app-breadcrumb>

<div class="row">
    <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="stats-card-box bg-purple">
            <div class="icon-box">
                <i class="bx bx-group purple-text"></i>
            </div>
            <span class="sub-title white-text">Registered Users</span>
            <h3 class="white-text">{{ users.length | number }}</h3>
            <div class="progress-list">
                <hr>
                <p class="white-text">Admins, Riders and Clients</p>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-3 col-xs-12">
        <div class="stats-card-box bg-green">
            <div class="icon-box">
                <i class='bx bx-cycling green-text'></i>
            </div>
            <span class="sub-title white-text">Riders</span>
            <h3 class="white-text">{{ riders.length | number }}</h3>
            <div class="progress-list">
                <hr>
                <p class="white-text">Registered</p>
            </div>
        </div>
    </div>
    <div class="col-lg-5 col-md-5 col-xs-12">
        <div class="stats-card-box bg-red">
            <div class="icon-box">
                <i class='bx bx-wallet-alt red-text'></i>
            </div>
            <span class="sub-title white-text">Amount in Wallets</span>
            <h3 class="white-text">
                <span [innerHtml]="naira" class="isSlim"></span>
                {{ sumArrayValues(wallets) | number : '1.2-2' }}
            </h3>
            <div class="progress-list">
                <hr>
                <p class="white-text">From {{ users.length | number }} users wallets</p>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-12 col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3 class="text-uppercase">Where are My riders At The Moment?</h3>
            </div>
            <div class="card-body">
                <div #map style="width: 100%; height: 550px"></div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-6 col-md-12">

        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3 class="text-uppercase">Top 10 Users Wallets</h3>
            </div>
            <div class="card-body widget-todo-list">
                <ul>

                    <li *ngFor="let wallet of wallets | slice:0:10">
                        <div class="todo-item-title">
                            <img [src]="getUserImage(wallet.id)" data-toggle="tooltip" data-placement="top"
                                title="Sarah Taylor" alt="image">
                            <h3>
                                {{ getUserFullName(wallet.id) }}
                                <small>( {{ getUserRole(wallet.id) }} )</small>
                            </h3>
                            <p>{{ getUserEmail(wallet.id) }} </p>
                        </div>
                        <div class="todo-item-action is-bold">
                            <span [innerHtml]="naira"></span>{{ wallet.balance | number: '1.2-2' }}
                        </div>
                    </li>

                </ul>
            </div>
        </div>

    </div>

    <div class="col-lg-6 col-md-12">

        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3 class="text-uppercase">Top 10 Riders Wallets</h3>
            </div>
            <div class="card-body widget-todo-list">
                <ul>

                    <li *ngFor="let riderWallet of riderWallets | slice:0:10">
                        <div class="todo-item-title">
                            <img [src]="getUserImage(riderWallet.id)" data-toggle="tooltip" data-placement="top"
                                title="Sarah Taylor" alt="image">
                            <h3>
                                {{ getUserFullName(riderWallet.id) }}
                                <small>( {{ getUserRole(riderWallet.id) }} )</small>
                            </h3>
                            <p>{{ getUserEmail(riderWallet.id) }} </p>
                        </div>
                        <div class="todo-item-action is-bold">
                            <span [innerHtml]="naira"></span>{{ riderWallet.balance | number: '1.2-2' }}
                        </div>
                    </li>

                </ul>
            </div>
        </div>

    </div>

</div>
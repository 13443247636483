import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { Profile } from 'src/app/interfaces/profile';
import { Theme } from 'src/app/services/theme-toggle.service';
import { SettingsService } from 'src/app/services/settings.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NewUserComponent } from '../diaog/new-user/new-user.component';
import { ViewUserComponent } from '../dialog/view-user/view-user.component';
import { Rider } from 'src/app/interfaces/rider';
import { RidersService } from 'src/app/services/riders.service';
import { NewRiderComponent } from '../dialog/new-rider/new-rider.component';
import { NavigationExtras, Router } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Wallet } from 'src/app/interfaces/payment';
import { PayRiderComponent } from 'src/app/dialog/pay-rider/pay-rider.component';


@Component({
  selector: 'app-riders',
  templateUrl: './riders.component.html',
  styleUrls: ['./riders.component.css']
})
export class RidersComponent implements OnInit {
  theme: Theme = 'light'
  isDark: boolean = false
  profile: any = {}
  token: string = ""
  year = new Date().getFullYear()
  age: number = 0
  dob: any = ""
  uid: string

  title = "Dispatch Riders"
  icon = "bx bx-cycling"
  crumbs = [
    { title: "Dashboard", icon: "bx bx-home", url: "/dashboard" },
    { title: "Dispatch Riders", icon: "bx bx-cycling", url: "/riders" },
    { title: "Overview", icon: "", url: null },
  ]

  pageTitle = 'Dispatch Riders'

  totalUsers = 0
  addUserTitle: string = 'Add New Rider'

  riders: Rider[] = [];
  naira = '&#8358;'

  // data Table
  ELEMENT_DATA: Rider[] = []
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns: string[] = [
    'imageUrl',
    'name',
    'online',
    'balance',
    'phone',
    'trips',
    'votes',
    'id'
  ];

  // PAss data source
  dataSource = new MatTableDataSource<Rider>(this.ELEMENT_DATA)

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Rider>;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  users: Profile[] = []
  riderWallets: Wallet[] = [];

  constructor(private titleService: Title, private formBuilder: FormBuilder, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService, private settingsService: SettingsService, public matDialog: MatDialog, private ridersService: RidersService, private router: Router, private dashboardService: DashboardService) {
    this.titleService.setTitle(this.pageTitle);

    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
    // Get user profile and token
    this.profile = _localStorageService.get('upd')
    this.token = _localStorageService.get('token')
    this.uid = _localStorageService.get('uid')
  }

  ngOnInit(): void {
    this.fetchUsers()
    this.fetchRiders()
    this.fetchRiderWallet()
  }

  // Fetch All Riders
  fetchRiders() {
    this.ridersService.getAllRiders().subscribe(res => {
      this.riders = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.riders.push(item as Rider)
      })
      this.dataSource.data = this.riders
    })
  }

  // Fetch all rider wallets
  fetchRiderWallet() {
    this.dashboardService.getAllRiderWallets().subscribe(res => {
      this.riderWallets = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.riderWallets.push(item as Wallet)
      })
      this.riderWallets = this.riderWallets
    })
  }

  // Get rider wallet balance
  getRiderWalletBalnce(uid: any) {
    var balance
    this.riderWallets.forEach(u => {
      if (u.id == uid) {
        balance = u.balance
      }
    })
    return balance
  }

  fetchUsers() {
    this.settingsService.getAllUsers().subscribe(res => {
      this.users = []
      this.totalUsers = 0
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.users.push(item as Profile)
        this.totalUsers++
      })
      this.dataSource.data = this.users
    })
  }

  // Filter table
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // Open new account dialog 
  newRiderDialog() {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(NewRiderComponent, { disableClose: false, data: this.users });
  }

  trackRider(data: any) {
    const navigationExtras: NavigationExtras = { state: { data: data.id } };
    this.router.navigate(['riders/rider/track'], navigationExtras);
  }

  // Open new account dialog 
  payRiderDialog(rider: any) {
    let data = {
      rider: rider,
      wallets: this.riderWallets
    }
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(PayRiderComponent, { disableClose: false, data: data });
  }

}

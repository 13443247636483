export class HttpError {

    private errorCode: string = "";

    get code(): string {
        return this.errorCode;
    }

    set code(value: string) {
        this.errorCode = value;
    }

    private errorTitle: string = "";

    get title(): string {
        return this.errorTitle;
    }

    set title(value: string) {
        this.errorTitle = value;
    }

    private errorMessage: string = "";

    get message(): string {
        return this.errorMessage;
    }

    set message(value: string) {
        this.errorMessage = value;
    }

    public static initWithCode(error: any): HttpError {
        const httpError = new HttpError();
        httpError.errorCode = error.code;
        httpError.errorTitle = error.title;
        httpError.errorMessage = error.message;
        return httpError;
    }

}
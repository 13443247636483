<app-breadcrumb [title]="title" [icon]="icon" [crumbs]="crumbs"></app-breadcrumb>

<div class="row">
    <div class="col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>WALLET BALANCE: </h3>

                <h1 class="wallet-balance"><small>N</small>{{ walletBalance | number }}</h1>
            </div>
            <div class="card-body">

                <table mat-table matSort [dataSource]="dataSource">

                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>Amount</th>
                        <td mat-cell *matCellDef="let data">
                            <h3>
                                <small>N</small>{{ data.amount | number }}
                            </h3>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Description</th>
                        <td mat-cell *matCellDef="let data">
                            {{ data.description }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="paymentType">
                        <th mat-header-cell *matHeaderCellDef>Payment Type</th>
                        <td mat-cell *matCellDef="let data">
                            {{ data.paymentType }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="timestamp">
                        <th mat-header-cell *matHeaderCellDef>Payment Date</th>
                        <td mat-cell *matCellDef="let data">
                            {{ data.timestamp.toMillis() | date:'yyyy/MM/dd h:mm:ss a'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let data">
                            <span *ngIf="data.paymentType == 'Card'">
                                <span class="badge badge-pill is-pill badge-success">
                                    Wallet Funded
                                </span>
                            </span>
                            <span *ngIf="data.paymentType != 'Card'">
                                <span class="badge badge-pill is-pill badge-danger">
                                    Wallet Debitted
                                </span>
                            </span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 50, 100, 250]">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
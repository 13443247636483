import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import { DashboardService } from 'src/app/services/dashboard.service';
import { RidersService } from 'src/app/services/riders.service';
import { SettingsService } from 'src/app/services/settings.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-pay-rider',
  templateUrl: './pay-rider.component.html',
  styleUrls: ['./pay-rider.component.css']
})
export class PayRiderComponent implements OnInit {

  hasError = false
  isLoading = false
  canProceed = false

  uid: any = 0
  resp: any

  rider: any
  riderWalletBalance: any = 0
  riderWallets: any = [];
  naira = '&#8358;'
  amount: number = 0
  amountEntered: number = 0

  constructor(private formBuilder: FormBuilder, private matDialog: MatDialog, public dialogRef: MatDialogRef<PayRiderComponent>, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService, private riderService: RidersService, private settingService: SettingsService, @Inject(MAT_DIALOG_DATA) public data: any, private dashboardService: DashboardService, private walletService: WalletService) {
    this.uid = _localStorageService.get('uid')
  }

  ngOnInit(): void {
    this.rider = this.data.rider
    this.riderWallets = this.data.wallets
    this.getRiderWalletBalnce(this.data.rider)
  }

  // 2835053

  modelChange(amnt: number): void {
    this.amountEntered = amnt;
  }

  form = this.formBuilder.group({
    amount: ['', { validators: [Validators.required], updateOn: "change" }],
  })

  async onSubmit() {
    if (this.form.invalid) {
      this.toastr.error('Provide an amount', 'Request Failed')
      return
    }

    this.isLoading = true

    this.amount = this.form.value.amount

    let balance = this.riderWalletBalance - this.amount

    let data = { id: this.uid, balance: balance }

    await this.walletService.updateRiderWallet(data).then(res => {
      this.isLoading = false
      this.dialogRef.close()
      this.toastr.success('Rider wallet successfully updated.', 'Request Successful')
    }).catch(err => {
      console.log(err)
      this.toastr.success(err, 'Request Failed to add to FS')
    })
  }

  // Get rider wallet balance
  getRiderWalletBalnce(rider: any) {
    var balance
    this.riderWalletBalance = 0
    this.riderWallets.forEach((u: any) => {
      if (u.id == rider.id) {
        balance = u.balance
        this.riderWalletBalance = balance
      }
    })
    return balance
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DispatchRequest } from '../interfaces/dispatch-request';
import { Tracker } from '../interfaces/tracker';

@Injectable({
  providedIn: 'root'
})
export class TrackerService {


  trackingCollection = 'trackings'

  dispatchRequestsCollection = this.db.collection('dispatchRequests')
  dispatchRequestSubCollection = 'requests'

  constructor(private db: AngularFirestore) {
  }

  // Get dispatch requests
  getDispatchRequests(id: string, uid: string): Observable<DispatchRequest> {
    return this.dispatchRequestsCollection.doc(uid).collection(this.dispatchRequestSubCollection).doc(id).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as DispatchRequest;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  // Get trackings 1630509023231
  getPackageTrackings(airwayBillNumber: number) {
    var abn = Number(airwayBillNumber)
    var res = this.db.collection<Tracker>('trackings', ref => ref.where('abn', '==', abn).orderBy('addedOn', 'desc')).snapshotChanges()
    return res;
  }
}

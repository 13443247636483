import { DOCUMENT } from '@angular/common';
import { ApplicationRef, Inject, Injectable } from '@angular/core';

export type Theme = 'light' | 'dark';

@Injectable({
  providedIn: 'root'
})
export class ThemeToggleService {
  currentTheme: Theme = 'light';

  constructor(@Inject(DOCUMENT) private document: Document, private ref: ApplicationRef) {
    // Initially check id=f dark mode is enabled
    let darkModeOn = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches

    // If dark mode is enabled then directly switch to the dark-theme
    if (darkModeOn) {
      this.currentTheme = 'dark'
      this.document.body.classList.add(this.currentTheme)
      this.switchTheme(this.currentTheme)
    }

    // Watch for changes of the preference
    window.matchMedia("(prefers-color-scheme: dark)").addListener(e => {
      let turnOn = e.matches;
      if (turnOn) {
        this.currentTheme = 'dark'
      } else {
        this.currentTheme = 'light'
      }
      this.switchTheme(this.currentTheme)

      // Trigger refresh of UI
      this.ref.tick();
    });
  }

  switchTheme(newTheme: Theme): void {
    this.document.body.classList.replace(this.currentTheme, newTheme)
    this.currentTheme = newTheme;
    // Switch css variable
    if (this.currentTheme === 'dark') {
      localStorage.setItem('theme', 'dark')
      // Set Dark Theme Variables --auth-form-area-bg
      this.document.documentElement.style.setProperty(
        "--auth-form-area-bg", "linear-gradient(45deg, #212121 35%, #fff 35%, #212121 30%)")
      this.document.documentElement.style.setProperty("--primary-color", "#ffffff")
      this.document.documentElement.style.setProperty("--primary-color-2", "#cccccc")
      this.document.documentElement.style.setProperty("--secondary-color", "#000000")
      this.document.documentElement.style.setProperty("--accent-color", "#a39793")
      this.document.documentElement.style.setProperty("--text-color", "#ffffff")
      this.document.documentElement.style.setProperty("--text-color-2", "#efefef")
      this.document.documentElement.style.setProperty("--scaffold-background-color", "#303030")
      this.document.documentElement.style.setProperty("--active-link-bg-color", "#000000")
      this.document.documentElement.style.setProperty("--app-toggle-btn-color", "#333333")
      this.document.documentElement.style.setProperty("--form-control-placeholder-color", "#efefef")
      this.document.documentElement.style.setProperty("--sidenav-bg-color", "#212121")
      this.document.documentElement.style.setProperty("--sidenav-shadow-color", "0 8px 10px 0 rgb(0 0 0 / 40%)")
    } else {
      localStorage.setItem('theme', 'light')
      // Set Light Theme Variables 
      this.document.documentElement.style.setProperty(
        "--auth-form-area-bg", "linear-gradient(45deg, #ccc 35%, #fff 35%, #ccc 30%)")

      this.document.documentElement.style.setProperty("--primary-color", "#af0000")
      this.document.documentElement.style.setProperty("--primary-color-2", "#9a0000")
      this.document.documentElement.style.setProperty("--secondary-color", "#ffffff")
      this.document.documentElement.style.setProperty("--accent-color", "#636363")
      this.document.documentElement.style.setProperty("--text-color", "#363636")
      this.document.documentElement.style.setProperty("--text-color-2", "#636363")
      this.document.documentElement.style.setProperty("--scaffold-background-color", "#f0eeee")
      this.document.documentElement.style.setProperty("--app-toggle-btn-color", "#ffffff")
      this.document.documentElement.style.setProperty("--active-link-bg-color", "#faeae5")
      this.document.documentElement.style.setProperty("--form-control-placeholder-color", "#636363")
      this.document.documentElement.style.setProperty("--sidenav-bg-color", "#ffffff")
      this.document.documentElement.style.setProperty("--sidenav-shadow-color", "rgba(0, 0, 0, 0.24) 0px 3px 8px")
    }
  }
}

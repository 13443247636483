<app-breadcrumb [title]="title" [icon]="icon" [crumbs]="crumbs"></app-breadcrumb>

<div class="row">
    <div class="col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>{{ lastName }} {{ firstName }}'s Dispatch Requests </h3>

                <div class="form-area">
                    <div class="form-field app-shadow">
                        <i class="bx bx-filter"></i>
                        <input type="text" placeholder="Type to filter" matInput (keyup)="applyFilter($event)" #input>
                    </div>
                </div>
            </div>
            <div class="card-body">

                <table mat-table matSort [dataSource]="dataSource">

                    <ng-container matColumnDef="addedOn">
                        <th mat-header-cell *matHeaderCellDef>Request On</th>
                        <td mat-cell *matCellDef="let data">
                            {{ data.addedOn | date: 'yyyy/MM/dd' }} <br>
                            {{ data.addedOn | date: 'h:mm:ss a' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="driverId">
                        <th mat-header-cell *matHeaderCellDef>Rider</th>
                        <td mat-cell *matCellDef="let data">
                            {{ getRiderFullName(data.driverId) }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="item">
                        <th mat-header-cell *matHeaderCellDef>Item</th>
                        <td mat-cell *matCellDef="let data" class="clickable pr-2 pl-2"
                            (click)="viewCellDialog(data.item)">
                            {{ data.item.itemType }} <br> View More
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="location">
                        <th mat-header-cell *matHeaderCellDef>location</th>
                        <td mat-cell *matCellDef="let data" class="clickable pr-2 pl-2"
                            (click)="viewCellDialog(data.location)">
                            View More
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="payment">
                        <th mat-header-cell *matHeaderCellDef>payment</th>
                        <td mat-cell *matCellDef="let data">
                            <h6 class="clickable" (click)="viewCellDialog(data.payment)">
                                Estimated: &#8358;{{ data.payment.estimatedCost | number }}
                            </h6>
                            <h6 class="clickable" (click)="viewCellDialog(data.payment)">
                                Actual: &#8358;{{ data.actualAmount | number }}
                            </h6>
                            <h6 class="clickable" (click)="viewCellDialog(data.payment)">
                                Balance: &#8358;{{ data.actualAmountBalance | number }}
                            </h6>
                            <button type="button" class="app-small-btn mt-1 mb-2" (click)="updatePriceDialog(data)">
                                Update Price
                            </button>

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="sender">
                        <th mat-header-cell *matHeaderCellDef>sender</th>
                        <td mat-cell *matCellDef="let data" class="clickable pr-2 pl-2"
                            (click)="viewCellDialog(data.sender)">
                            {{ data.sender.senderName }}<br> View More
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="pickupPin">
                        <th mat-header-cell *matHeaderCellDef>PINS</th>
                        <td mat-cell *matCellDef="let data">
                            Pickup: <br><strong>{{ data.pickupPin }}</strong> <br>
                            Delivery: <br><strong>{{ data.deliveryPin }}</strong>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="recipient">
                        <th mat-header-cell *matHeaderCellDef>recipient</th>
                        <td mat-cell *matCellDef="let data" class="clickable pr-2 pl-2"
                            (click)="viewCellDialog(data.recipient)">
                            {{ data.recipient.recipientName }}<br> View More
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>status</th>
                        <td mat-cell *matCellDef="let data">
                            <span *ngIf="data.status == 'Delivered'">
                                <span class="badge badge-pill is-pill badge-success">
                                    {{ data.status }}
                                </span>
                            </span>
                            <span *ngIf="data.status == 'Initiated'">
                                <span class="badge badge-pill is-pill badge-light">
                                    {{ data.status }}
                                </span>
                            </span>
                            <span *ngIf="data.status == 'Picked Up'">
                                <span class="badge badge-pill is-pill badge-primary">
                                    {{ data.status }}
                                </span>
                            </span>
                            <span *ngIf="data.status == 'Accepted'">
                                <span class="badge badge-pill is-pill badge-dark">
                                    {{ data.status }}
                                </span>
                            </span>
                            <span *ngIf="data.status == 'Enroute'">
                                <span class="badge badge-pill is-pill badge-info">
                                    {{ data.status }}
                                </span>
                            </span>

                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 50, 100, 250]">
                </mat-paginator>
            </div>
        </div>

    </div>

</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import * as jQuery from 'jquery';
import { User } from 'src/app/interfaces/user';
import { Profile } from 'src/app/interfaces/profile';
import { Subscription } from 'rxjs/internal/Subscription';
import { UserService } from 'src/app/services/user.service';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const PRE_UID = environment.preUid;

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  pageTitle = 'Sign In'
  info = {
    title: "Welcome",
    subtitle: "Enter your credentials to sign in",
  }

  hide = true
  hasError = false
  isLoading = false
  returnUrl: string = ""
  errorData: any
  isSignedIn: any = false
  msg: any = ""
  email: string = ""
  password: string = ""
  userToken: any = ""
  uid: any = 0
  resp: any
  data: User[] = []
  profile: Profile = {
    id: "",
    accountStatus: 0,
    chattingWith: "",
    email: "",
    firstName: "",
    lastName: "",
    gender: "",
    imageUrl: "",
    phone: "",
    position: {},
    pushToken: "",
    rating: "",
    role: "",
    token: "",
    trips: 0,
    votes: 0,
  }

  theme?: any
  isDark = false

  message: string = "";
  loginSubscription: Subscription = new Subscription();

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private titleService: Title, private userService: UserService, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService) {
    this.titleService.setTitle(this.pageTitle);

    this.theme = localStorage.getItem('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }

    this.currentUserSubject = new BehaviorSubject<any>(_localStorageService.get('uid'))
    this.currentUser = this.currentUserSubject.asObservable();

    this.uid = _localStorageService.get('uid')
    this.userToken = _localStorageService.get('token')
    // Check if user ID and Token exists
    if (this.userToken !== null) {
      this.router.navigate(['/'])
    }

  }

  ngOnInit(): void {
    // Get the return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'

    // Check if user ID and Token exists
    if (this.userToken === null) {
      this.isSignedIn = false
    } else {
      this.router.navigate(['/dashboard'])
    }
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  form = this.formBuilder.group({
    email: ['', { validators: [Validators.required, Validators.email], updateOn: "change" }],
    password: ['', { validators: [Validators.required, Validators.minLength(6)], updateOn: "change" }]
  })

  async onSubmit() {
    if (this.form.invalid) {
      this.hasError = true
      this.toastr.error('Provde valid credentials', 'Request Failed')
      return
    }

    this.isLoading = true

    this.loginSubscription = await this.userService.signInWithUserCredentials(this.form.value.email, this.form.value.password).pipe(finalize(() => this.isLoading = false)).subscribe(
      res => {
        let data = res.user;

        this.uid = PRE_UID + data.id
        this.profile.id = PRE_UID + data.id
        this.profile.lastName = data.lastName
        this.profile.firstName = data.firstName
        this.profile.email = res.email
        this.profile.imageUrl = data.imageUrl
        this.profile.phone = data.phoneNumber
        this.profile.gender = data.gender
        this.profile.role = data.roles

        this._localStorageService.set('token', res.token)
        this._localStorageService.set('uid', this.uid)
        this._localStorageService.set('upd', this.profile)

        this.isSignedIn = true
        this.isLoading = false
        this.toastr.success(res.message, 'Authorised')

        this.currentUserSubject.next(data);

        if (this.isSignedIn === true) {
          this.router.navigate(['/'])
        }
      },
      error => {
        this.errorData = error
        this.isSignedIn = false
        this.isLoading = false
        this.toastr.error('Authentication denied due to invalid credentials', 'Request Failed')
      }
    )
  }

}


import { Component, OnInit } from '@angular/core';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { Theme } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  theme: Theme = 'light'
  isDark: boolean = false

  menu = [
    {
      title: "Dashboard",
      url: '/dashboard',
      icon: 'bx bxs-layout',
      alerts: 0
    },
    {
      title: "Profile",
      url: '/profile',
      icon: 'bx bx-user',
      alerts: 0
    },
    // {
    //   title: "Chats",
    //   url: '/chats',
    //   icon: 'bx bx-message',
    //   alerts: 1
    // },
    // {
    //   title: "Notifications",
    //   url: '/notifications',
    //   icon: 'bx bx-bell',
    //   alerts: 0
    // },
    {
      title: "Dispatch Requests",
      url: '/dispatch/requests',
      icon: 'bx bx-box',
      alerts: 0
    },
    {
      title: "Wallets",
      url: '/wallets',
      icon: 'bx bx-wallet',
      alerts: 0
    },
    {
      title: "Riders",
      url: '/riders',
      icon: 'bx bx-cycling',
      alerts: 0
    },
    {
      title: "Package Tracker",
      url: '/dispatch/tracker',
      icon: 'bx bx-map-pin',
      alerts: 0
    },
    {
      title: "Support",
      url: '/app/support',
      icon: 'bx bx-support',
      alerts: 0
    },
    {
      title: "Settings",
      url: '/app/settings',
      icon: 'bx bx-cog',
      alerts: 0
    },
  ]

  constructor(private _localStorageService: LocalStorageServiceEncrypt) {
    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
  }

  ngOnInit(): void {
  }

}

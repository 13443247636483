<app-breadcrumb [title]="title" [icon]="icon" [crumbs]="crumbs"></app-breadcrumb>

<div class="row">
    <div class="col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Wallets</h3>
            </div>
            <div class="card-body">

                <table mat-table matSort [dataSource]="dataSource">

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let data">
                            <span *ngFor="let user of users">
                                <span *ngIf="user.id == data.id">
                                    <b>{{ user.firstName }} {{ user.lastName}}</b> <br>
                                    {{ user.phone }}
                                </span>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef>Account Type</th>
                        <td mat-cell *matCellDef="let data">
                            <span *ngFor="let user of users">
                                <span *ngIf="user.id == data.id">
                                    {{ user.role }}
                                </span>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="balance">
                        <th mat-header-cell *matHeaderCellDef>Available Balance</th>
                        <td mat-cell *matCellDef="let data">
                            <h2>
                                <small>N</small>{{ data.balance | number }}
                            </h2>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ACTIONS</th>
                        <td mat-cell *matCellDef="let data">
                            <button type="button" class="app-small-btn error mt-1 mb-2" (click)="viewPayments(data)">
                                <i class="bx bx-show-alt white-text mr-1"></i>
                                View Transaction Payments
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 50, 100, 250]">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
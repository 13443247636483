import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  profileCollection = this.db.collection('users')
  riders = this.db.collection('riders')
  riderCollection = 'riders'
  wallets = this.db.collection('wallets')
  walletCollection = 'wallets'
  dispatchCollection = this.db.collection('dispatchRequests')

  constructor(private db: AngularFirestore) { }

  // Get all users
  getAllUsers() {
    return this.profileCollection.snapshotChanges();
  }

  // Get all riders
  getAllRiders() {
    return this.riders.snapshotChanges();
  }

  // Get all wallets
  getAllWallets() {
    return this.db.collection('wallets', ref => ref.orderBy('balance', 'desc')).snapshotChanges();
  }

  // Get all rider wallets
  getAllRiderWallets() {
    return this.db.collection('riderWallets', ref => ref.orderBy('balance', 'desc')).snapshotChanges();
  }

  // Get all dispatch requests
  getAllDispatchRequests() {
    return this.dispatchCollection.snapshotChanges();
  }

}

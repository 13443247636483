import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { Theme } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  theme: Theme = 'light'
  isDark: boolean = false

  @Input() title: string = ""
  @Input() icon: string = ""
  @Input() crumbs: any = []

  constructor(private _localStorageService: LocalStorageServiceEncrypt) {
    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
  }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Gender } from 'src/app/interfaces/gender';
import { SettingsService } from 'src/app/services/settings.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

const PRE_UID = environment.preUid;

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {

  hasError = false
  isLoading = false
  errorData: any
  msg: any = ""

  lastName: string
  firstName: string
  phoneNumber: string
  gender: string
  email: string
  password: string
  confirmPassword: string

  uid: any = 0
  resp: any

  imageUrl: string = 'https://res.cloudinary.com/djnqxvljr/image/upload/v1616065014/man_use_kspnfo.png'

  subscription: Subscription = new Subscription();

  genders: Gender[] = [
    { value: 'Male', viewValue: 'Male' },
    { value: 'Female', viewValue: 'Female' },
    { value: 'others', viewValue: 'Others' }
  ];

  constructor(private formBuilder: FormBuilder, private settingsService: SettingsService, private matDialog: MatDialog, public dialogRef: MatDialogRef<NewUserComponent>, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService, private userService: UserService) {
    this.uid = _localStorageService.get('uid')
  }

  ngOnInit(): void {
  }

  form = this.formBuilder.group({
    lastName: ['', { validators: [Validators.required], updateOn: "change" }],
    firstName: ['', { validators: [Validators.required], updateOn: "change" }],
    gender: [''],
    phoneNumber: ['', { validators: [Validators.required], updateOn: "change" }],
    email: ['', { validators: [Validators.required, Validators.email, Validators.maxLength(80)], updateOn: "change" }],
    password: ['', { validators: [Validators.required, Validators.minLength(8), Validators.maxLength(30)], updateOn: "change" }],
    confirmPassword: ['', { validators: [Validators.required, Validators.minLength(8), Validators.maxLength(30)], updateOn: "change" }],
  })

  /**
   * Sign User In
   * @param lastName
   * @param firstName
   * @param otherNames
   * @param gender
   * @param phoneNumber
   * @param email
   * @param password
   * @param confirmPassword
   * @returns user data
   */
  async onSubmit() {
    if (this.form.invalid) {
      this.hasError = true
      this.toastr.error('Provide valid credentials', 'Request Failed')
      return
    }

    this.isLoading = true

    this.lastName = this.form.value.lastName
    this.firstName = this.form.value.firstName
    this.phoneNumber = this.form.value.phoneNumber
    this.email = this.form.value.email
    this.password = this.form.value.password
    this.confirmPassword = this.form.value.confirmPassword

    this.subscription = await this.userService.signUpWithUserCredentials(this.lastName, this.firstName, this.phoneNumber, this.email, this.password, this.confirmPassword).pipe(finalize(() => this.isLoading = false)).subscribe(
      res => {
        let data = res.user;

        let userId = PRE_UID + data.id

        // Add record to firebase
        let payload = {
          accountStatus: 1,
          chattingWith: '',
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          gender: null,
          id: userId,
          imageUrl: this.imageUrl,
          phone: this.phoneNumber,
          position: {
            accuracy: 1951,
            altitude: 0,
            floor: null,
            heading: 90,
            is_mocked: false,
            latitude: 6.4847612,
            longitude: 3.5958455,
            speed: 0,
            speed_accuracy: 0,
            timestamp: 1624693132857
          },
          pushToken: '',
          rating: 0,
          role: 'Client',
          token: '',
          trips: 0,
          votes: 0,
          addedBy: this.uid
        }
        this.settingsService.createNewUser(payload).then(res => {
          this.isLoading = false
          this.dialogRef.close()
          this.toastr.success('A new user acount has been successfully created.', 'Request Successful')
        }).catch(err => {
          console.log(err)
          this.toastr.success(err, 'Request Failed to add to FS')
        })
      },
      error => {
        this.errorData = error
        console.log(error)
        this.isLoading = false
      }
    )

  }

}

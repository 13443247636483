import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class DispatchRequestService {

  constructor(private db: AngularFirestore) { }

  collection = this.db.collection('dispatchRequests')
  collectionName = 'dispatchRequests'

  subCollectionName = 'requests'

  // Get all request
  getAllDispatchRequests() {
    return this.collection.snapshotChanges();
  }

  // Get all dispactch request
  getAllRequests(uid: string) {
    return this.collection.doc(uid).collection(this.subCollectionName).snapshotChanges();
  }

  // Update request
  updateRequestPrice(id: any, estimatedCost: any, amount: any, uid: any, title: any, airwayBillNumber: any) {
    let p = {
      id: id,
      amount: amount,
      uid: uid,
      title: title,
      airwayBillNumber: airwayBillNumber,
    }

    let toBalance = amount - estimatedCost;
    let data = {
      actualAmount: amount,
      actualAmountBalance: toBalance,
      actualAmountPaid: false
    }

    // Add notification
    let amnt = this.numberWithCommas(amount)
    let notData = {
      uid: uid,
      isSms: true,
      title: 'Price Updated',
      message: 'The price for your dispatch item with title: ' + title + ' and Airway Bill Number: ' + airwayBillNumber + ' has been update to N' + amnt + '. The price update is as result of difference in weight.'
    }
    this.db.collection('notifications').add(notData)

    return new Promise<any>((resolve, reject) => {
      this.collection.doc(uid).collection(this.subCollectionName).doc(id).update(data).then(res => resolve(res), err => reject(err))
    })
  }

  // Numbers with comma
  numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

}

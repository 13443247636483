<h4 mat-dialog-title class="text-uppercase"> information</h4>
<hr>
<div class="row" style="width: 700px">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12" *ngFor="let item of data | keyvalue">
                {{ item.key }}: <br>
                <span *ngIf="item.key == 'imageUrl' ">
                    <img [src]="item.value" alt="Pics" width="250">
                </span>
                <span *ngIf="item.key == 'senderImage' ">
                    <img [src]="item.value" alt="Pics" width="250">
                </span>
                <strong>
                    {{ item.value }}
                </strong>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-10"></div>
    <div class="col-md-2">
        <button class="app-small-btn" (click)="close()"> close </button>
    </div>
</div>
import { catchError, finalize, tap, map, timeout } from 'rxjs/internal/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpError } from './http-error';

const APP_XHR_TIMEOUT = 30000;

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.performRequest(request)).pipe(
            timeout(APP_XHR_TIMEOUT),
            map((res) => this.handleSuccessfulResponse(res)),
            catchError((err) => this.handleErrorResponse(err)),
            finalize(this.handleRequestCompleted.bind(this))
        )
    }

    private performRequest(req: HttpRequest<any>): HttpRequest<any> {
        let headers: HttpHeaders = req.headers;
        headers = headers.set('appCustomKey', 'appCustomHeaderValue')
        return req.clone({ url: `${environment.REST_API_SERVER}/${req.url}`, headers })
    }

    handleSuccessfulResponse(event: any): HttpResponse<any> {
        if (event instanceof HttpResponse) {
            event = event.clone({ body: event.body.response })
        }
        return event
    }

    private handleErrorResponse(errorResponse: any): Observable<HttpEvent<any>> {
        if (errorResponse instanceof TimeoutError) {
            return throwError('Timeout Exception');
        }

        switch (errorResponse.status) {
            case 401: // Unauthorized
                break;
            case 503: // Service Unavailable
                break;
            case 503: // Internal Server Error
                break;
            default: // Other Error
        }

        let customError = new HttpError();
        try {
            var error = {
                code: errorResponse.status,
                title: errorResponse.error.message,
                message: errorResponse.error.errors
            }
            // console.log(error)
            customError = HttpError.initWithCode(error);
        } catch (e) { }

        return throwError(customError);
    }

    private handleRequestCompleted(): void {
        // console.log(`Request finished`);
    }
}

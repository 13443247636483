import { Injectable } from '@angular/core';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _localStorageService: LocalStorageServiceEncrypt) { }

  public isAuthenticated(): boolean {
    const token = this._localStorageService.get('token')

    if (token !== null) {
      return true
    } else {
      return false
    }
  }
}

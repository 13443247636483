<app-breadcrumb [title]="title" [icon]="icon" [crumbs]="crumbs"></app-breadcrumb>

<div class="row">
    <div class="col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Request From All Users</h3>

                <div class="form-area">
                    <div class="form-field app-shadow">
                        <i class="bx bx-filter"></i>
                        <input type="text" placeholder="Type to filter" matInput (keyup)="applyFilter($event)" #input>
                    </div>
                </div>
            </div>
            <div class="card-body">

                <table mat-table matSort [dataSource]="dataSource">

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let data">
                            {{ data.lastName }} {{ data.firstName }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>Email Address</th>
                        <td mat-cell *matCellDef="let data">{{ data.email }}</td>
                    </ng-container>

                    <ng-container matColumnDef="phoneNumber">
                        <th mat-header-cell *matHeaderCellDef>Phone Number</th>
                        <td mat-cell *matCellDef="let data">{{ data.phone }}</td>
                    </ng-container>

                    <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef>Account Type</th>
                        <td mat-cell *matCellDef="let data">{{ data.role }}</td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ACTIONS</th>
                        <td mat-cell *matCellDef="let data">
                            <button type="button" class="app-small-btn error mt-1 mb-2"
                                (click)="viewDispatchRequestSubCollections(data)">
                                <i class="bx bx-show-alt white-text mr-1"></i>
                                View Dispatch Requests
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 50, 100, 250]">
                </mat-paginator>
            </div>
        </div>

    </div>

</div>
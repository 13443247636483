import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { Wallet } from 'src/app/interfaces/payment';
import { Profile } from 'src/app/interfaces/profile';
import { Rider } from 'src/app/interfaces/rider';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Theme } from 'src/app/services/theme-toggle.service';
import MarkerClusterer from "@google/markerclustererplus";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit, AfterViewInit {

  theme: Theme = 'light'
  isDark: boolean = false
  token: string = ""

  year = new Date().getFullYear()
  age: number = 0
  dob: any = ""
  uid: string

  title = "Dashboard"
  icon = "bx bx-home"
  crumbs = [
    { title: "Dashboard", icon: "bx bx-home", url: "/dashboard" },
    { title: "Overview", icon: "", url: null },
  ]

  users: Profile[] = [];
  riders: Rider[] = [];
  wallets: Wallet[] = [];
  riderWallets: Wallet[] = [];
  requests: Request[] = [];
  totalAmountInWallets = 0.00;

  isLoading: boolean = false
  naira = '&#8358;'

  // Use @ViewChild decorator with the template reference variable ‘map’
  @ViewChild('map', { static: false }) mapElement: ElementRef;
  map: google.maps.Map;
  center = new google.maps.LatLng(6.4385, 3.4289);
  marker: google.maps.Marker;
  mapMarkers: any = [];
  markerCluster: any;
  mapOptions: google.maps.MapOptions = {
    center: this.center,
    zoom: 11
  };

  infoWindow: google.maps.InfoWindow;

  locations: any = []

  mapIcon = '../../../assets/images/icon45.png'

  constructor(private titleService: Title, private _localStorageService: LocalStorageServiceEncrypt, private router: Router, private dashboardService: DashboardService) {
    this.titleService.setTitle(this.title);

    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
  }

  ngOnInit(): void {
    this.fetchUsers()
    this.fetchRiders()
    this.fetchWallets()
    this.fetchRiderWallet()
  }

  ngAfterViewInit() {
    this.fetchRidersMap();
  }

  // Implement Google map marker
  mapInit() {
    this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);

    this.infoWindow = new google.maps.InfoWindow()
    for (let i of this.locations) {
      const tempMarker = new google.maps.Marker({
        position: i.position,
        map: this.map,
        icon: this.mapIcon
      })

      tempMarker.addListener('click', ((tempMarker: any, map: any, infoWindow: any) => {
        let status = 'Offline'
        if (i.online == true) {
          status = 'Online'
        }
        return () => {
          infoWindow.setContent('<p>Rider: <b> ' + i.name + '</b><br />Phone: <b> ' + i.phone + '</b><br />Status: <b> ' + status + '</b><br />Location: <b> ' + i.currentAddress + '</b></p>');

          infoWindow.open(map, tempMarker)
        }

      })(tempMarker, this.map, this.infoWindow))

      this.mapMarkers.push(tempMarker)

    }

    // Marker Cluster
    // this.markerCluster = new MarkerClusterer(this.map,
    //   this.mapMarkers,
    //   { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
  }

  // Fetch All Users
  fetchUsers() {
    this.dashboardService.getAllUsers().subscribe(res => {
      this.users = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.users.push(item as Profile)
      })
    })
  }

  // Fetch All Riders
  fetchRiders() {
    this.dashboardService.getAllRiders().subscribe(res => {
      this.riders = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.riders.push(item as Rider)
      })
    })
  }

  // for map
  fetchRidersMap() {
    this.dashboardService.getAllRiders().subscribe(res => {
      this.locations = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        let location: any = { position: { lat: item.lat, lng: item.lng }, name: item.name, currentAddress: item.currentAddress, phone: item.phone, online: item.online }
        this.locations.push(location)
      })
      this.mapInit()
    })
  }

  // Fetch all users wallets
  fetchWallets() {
    this.dashboardService.getAllWallets().subscribe(res => {
      this.wallets = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.wallets.push(item as Wallet)
      })
      this.wallets = this.wallets
    })
  }

  // Fetch all rider wallets
  fetchRiderWallet() {
    this.dashboardService.getAllRiderWallets().subscribe(res => {
      this.riderWallets = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.riderWallets.push(item as Wallet)
      })
      this.riderWallets = this.riderWallets
    })
  }

  // Fetch all dispatch request users
  fetchDispatchRequests() {
    this.dashboardService.getAllDispatchRequests().subscribe(res => {
      this.requests = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.requests.push(item as Request)
      })
    })
  }

  // Sum values in an array
  sumArrayValues(data: any) {
    this.totalAmountInWallets = 0
    data.forEach((a: any) => {
      this.totalAmountInWallets += a.balance
    });
    return this.totalAmountInWallets;
  }

  // Get user Name
  getUserFullName(uid: any) {
    var name
    this.users.forEach(u => {
      if (u.id == uid) {
        name = u.lastName + ' ' + u.firstName
      }
    })
    return name
  }

  // Get user Email
  getUserEmail(uid: any) {
    var emailAddress
    this.users.forEach(u => {
      if (u.id == uid) {
        emailAddress = u.email
      }
    })
    return emailAddress
  }

  // Get user Image
  getUserImage(uid: any) {
    var image
    this.users.forEach(u => {
      if (u.id == uid) {
        image = u.imageUrl
      }
    })
    return image
  }

  // Get user Role
  getUserRole(uid: any) {
    var role
    this.users.forEach(u => {
      if (u.id == uid) {
        role = u.role
      }
    })
    return role
  }

}

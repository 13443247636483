<div class="breadcrumb-area">
    <h1>
        <i [ngClass]="icon"></i> {{ title }}
    </h1>
    <ol class="breadcrumb">
        <li class="item" *ngFor="let item of crumbs">
            <a [routerLink]="item.url">
                {{ item.title }}
            </a>
        </li>
    </ol>
</div>
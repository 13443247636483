<app-breadcrumb [title]="title" [icon]="icon" [crumbs]="crumbs"></app-breadcrumb>

<div class="row">
    <div class="col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Registered Dipatch Riders</h3>
                <div class="form-area">
                    <div class="form-field app-shadow">
                        <i class="bx bx-filter"></i>
                        <input type="text" placeholder="Type to filter" matInput (keyup)="applyFilter($event)" #input>
                    </div>

                </div>
                <div style="width: 220px;">
                    <button type="button" class="app-toggle-btn" (click)="newRiderDialog()">
                        <i class="bx bx-user-plus mr-10 btn-icon"></i>
                        {{ addUserTitle }}
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">

                        <table mat-table matSort [dataSource]="dataSource">

                            <ng-container matColumnDef="imageUrl">
                                <th mat-header-cell *matHeaderCellDef>Picture</th>
                                <td mat-cell *matCellDef="let data">
                                    <span class="small-circle with-shadow">
                                        <img [src]="data.imageUrl" alt="A" width="40" height="40">
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="online">
                                <th mat-header-cell *matHeaderCellDef>Status</th>
                                <td mat-cell *matCellDef="let data">
                                    <span *ngIf="data.online">Online</span>
                                    <span *ngIf="!data.online">Offline</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>Name</th>
                                <td mat-cell *matCellDef="let data">{{ data.name }}</td>
                            </ng-container>

                            <ng-container matColumnDef="balance">
                                <th mat-header-cell *matHeaderCellDef>Balance</th>
                                <td mat-cell *matCellDef="let data" class="isBold">
                                    <span [innerHtml]="naira" class="isSlim"></span>
                                    {{ getRiderWalletBalnce(data.id)| number: '1.2-2' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="phone">
                                <th mat-header-cell *matHeaderCellDef>Phone Number</th>
                                <td mat-cell *matCellDef="let data">{{ data.phone }}</td>
                            </ng-container>

                            <ng-container matColumnDef="trips">
                                <th mat-header-cell *matHeaderCellDef>Trips</th>
                                <td mat-cell *matCellDef="let data">{{ data.trips }} </td>
                            </ng-container>

                            <ng-container matColumnDef="votes">
                                <th mat-header-cell *matHeaderCellDef>Votes</th>
                                <td mat-cell *matCellDef="let data">{{ data.votes }}</td>
                            </ng-container>

                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>ACTIONS</th>
                                <td mat-cell *matCellDef="let data">
                                    <button type="button" class="app-small-btn teal mt-1 mb-2 mr-2"
                                        (click)="payRiderDialog(data)">
                                        <i class="bx bx-wallet white-text mr-1"></i>
                                        Credit Rider
                                    </button>
                                    <button type="button" class="app-small-btn error mt-1 mb-2 mr-2">
                                        <i class="bx bx-phone white-text mr-1"></i>
                                        Call
                                    </button>
                                    <button type="button" class="app-small-btn orange mt-1 mb-2"
                                        (click)="trackRider(data)">
                                        <i class="bx bx-map-pin white-text mr-1"></i>
                                        Track
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>

                        <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
                            [pageSizeOptions]="[5, 10, 25, 50, 100, 250]">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { Payment, Wallet } from 'src/app/interfaces/payment';
import { SettingsService } from 'src/app/services/settings.service';
import { Theme } from 'src/app/services/theme-toggle.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  theme: Theme = 'light'
  isDark: boolean = false

  title = "Wallets"
  icon = "bx bx-money"
  crumbs = [
    { title: "Dashboard", icon: "bx bx-home", url: "/dashboard" },
    { title: "Wallets", icon: "bx bx-money", url: "/wallets" },
    { title: "Overview", icon: "", url: null },
  ]

  users: any

  payments: Payment[] = [];

  // data Table
  ELEMENT_DATA: Payment[] = []
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns: string[] = [
    'amount',
    'description',
    'paymentType',
    'timestamp',
    'status'
  ];

  // PAss data source
  dataSource = new MatTableDataSource<Payment>(this.ELEMENT_DATA)

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Payment>;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  balance: any
  wid: string

  walletId: any
  walletBalance: any

  constructor(private titleService: Title, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService, private walletService: WalletService, private settingsService: SettingsService, private router: Router) {

    const navigation = this.router.getCurrentNavigation()
    const state = navigation?.extras.state as { wid: string, balance: any }
    this.wid = state?.wid
    this.balance = state?.balance

    // Check if data exist on localStorage
    if (this.wid != null) {
      localStorage.setItem('wid', this.wid)
    }
    if (this.balance != null) {
      localStorage.setItem('balance', this.balance)
    }
    this.walletId = localStorage.getItem('wid');
    this.walletBalance = localStorage.getItem('balance');

    this.titleService.setTitle(this.title);

    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
  }

  ngOnInit(): void {
    if (this.walletId != null) {
      this.fetchData()
    }
  }

  // Fetch All Users
  fetchData() {
    this.walletService.getPayments(this.walletId).subscribe(res => {
      this.payments = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.payments.push(item as Payment)
      })
      this.dataSource.data = this.payments
    })
  }

  // Filter table
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { User } from 'src/app/interfaces/user';
import { Theme } from 'src/app/services/theme-toggle.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  theme: Theme = 'light'
  isDark: boolean = false
  profile: any = {}
  token: string = ""
  year = new Date().getFullYear()
  age: number = 0
  dob: any = ""

  title = "Profile"
  icon = "bx bx-user"
  crumbs = [
    { title: "Dashboard", icon: "bx bx-home", url: "/dashboard" },
    { title: "Overview", icon: "", url: null },
  ]

  pageTitle = 'Profile'

  password_change_info = {
    title: "Change Account Password",
    subtitle: "Enter your new password and confirm inputted password to complete process. Please note that the system will sign you out once your request successful.",
  }

  hide = true
  hasError = false
  isLoading = false
  returnUrl: string = ""
  errorData: any = []
  isSuccess: any = false
  msg: any = ""
  lastName: string = ""
  firstName: string = ""
  gender: string = ""
  phone: string = ""
  email: string = ""
  password: string = ""
  passwordConfirmation: string = ""
  userToken: any = ""
  uid: number = 0
  resp: any
  noMatch = "Passwords do not match."
  acceptTermsConditions = false
  data: User[] = []
  loginSubscription: Subscription = new Subscription();
  timeAgoLive: boolean = true
  profileImage = 'assets/images/icon.png'

  constructor(private titleService: Title, private formBuilder: FormBuilder, private userService: UserService, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService) {
    this.titleService.setTitle(this.pageTitle);

    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
    // Get user profile and token
    this.profile = _localStorageService.get('upd')
    this.token = _localStorageService.get('token')
    this.uid = _localStorageService.get('id')
  }

  ngOnInit(): void {
  }


  // Image form
  changePasswordForm: FormGroup = this.formBuilder.group({
    password: ['', { validators: [Validators.required, Validators.minLength(8), Validators.maxLength(30)], updateOn: "change" }],
    password_confirmation: ['', { validators: [Validators.required, Validators.minLength(8), Validators.maxLength(30)], updateOn: "change" }],
  })

  /**
   * Change settings
   * @param email
   * @param pasword
   * @param password_confirmation
   */
  async changePassword() {
    // Stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      this.toastr.error('Kindly fill the form with accurate information. Both fields are required.', 'Request Failed')
      return
    }

    this.isLoading = true

    this.password = this.changePasswordForm.value.password
    this.passwordConfirmation = this.changePasswordForm.value.password_confirmation

    this.loginSubscription = await this.userService.changeUserPassword(this.uid, this.changePasswordForm.value.password, this.changePasswordForm.value.password_confirmation, this.token).pipe(finalize(() => this.isLoading = false)).subscribe(
      res => {
        this.toastr.success('Your password has been successfully changed.', 'Request Successful')
        this._localStorageService.clearAll()
        window.location.reload()
      },
      error => {
        this.errorData = error
        this.isLoading = false
        this.hasError = true
        this.toastr.error('Kindly fill the form with accurate information.', 'Request Failed')
      }
    )
  }

  // settings form
  settingsForm: FormGroup = this.formBuilder.group({
    file: ['', { validators: [Validators.required], updateOn: "change" }],
    fileSource: ['', { validators: [Validators.required], updateOn: "change" }],
  })

  /**
   * Change settings
   * @param email
   * @param pasword
   * @param pasword
   */
  async changeSettings() { }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Trip } from 'src/app/interfaces/trip';
import { DispatchRequestService } from 'src/app/services/dispatch-request.service';
import { Theme } from 'src/app/services/theme-toggle.service';
import { TripService } from 'src/app/services/trip.service';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css']
})
export class TrackingComponent implements OnInit {
  theme: Theme = 'light'
  isDark: boolean = false

  title = "Tracker"
  icon = "bx bx-map"
  crumbs = [
    { title: "Dashboard", icon: "bx bx-home", url: "/dashboard" },
    { title: "Wallets", icon: "bx bx-map", url: "/tracker" },
    { title: "Overview", icon: "", url: null },
  ]

  isLoading: boolean = false

  trips: any = [];
  selectedRequest: any = []

  rId: any
  riderId: any

  // data Table
  ELEMENT_DATA: Trip[] = []
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns: string[] = [
    'imageUrl',
    'name',
    'online',
    'gender',
    'phone',
    'trips',
    'votes',
    'id'
  ];

  // PAss data source
  dataSource = new MatTableDataSource<Trip>(this.ELEMENT_DATA)

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Trip>;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  constructor(private formBuilder: FormBuilder, private titleService: Title, private toastr: ToastrService, private router: Router, private tripService: TripService, private dispatchRequestService: DispatchRequestService) {

    const navigation = this.router.getCurrentNavigation()
    const state = navigation?.extras.state as { riderId: any }
    this.rId = state?.riderId

    // Check if data exist on localStorage
    if (this.rId != null) {
      localStorage.setItem('rider', this.rId)
    }
    this.riderId = localStorage.getItem('rider');

    this.titleService.setTitle(this.title);

  }

  ngOnInit(): void {
  }

  // Fetch All dispatch
  fetchData() {
    this.tripService.getTrips(this.riderId).subscribe(res => {
      this.trips = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.trips.push(item as Trip)
      })
      this.dataSource.data = this.trips
    })
  }

  // Fetch all dispatch requests for a client
  // fetchClientDispatchRequests() {
  //   this.dispatchRequestService.getAllRequests()
  // }

  // Filter table
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // AutoComplete
  keyword = 'addedOn';
  selectRequestEvent(item: any) {
    this.selectedRequest = item
  }

  form = this.formBuilder.group({
    airwayBillNumber: ['', { validators: [Validators.required], updateOn: "change" }],
  })

  async trackItem() {
    if (this.form.invalid) {
      this.toastr.error('Provide item airway bill number', 'Request Failed')
      return
    }

    this.isLoading = true

    let airwayBillNumber = this.form.value.amount

    console.log('RIDER ID: ', this.riderId)
    console.log('airwayBillNumber: ', airwayBillNumber)

  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { Profile } from 'src/app/interfaces/profile';
import { Theme, ThemeToggleService } from 'src/app/services/theme-toggle.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css']
})
export class TopnavComponent implements OnInit {

  theme: Theme = 'light'
  isDark: boolean = false
  profile: Profile = {}
  token: string = ""

  // profile menu
  profileMenu = [
    {
      title: 'Profile',
      url: '/profile',
      icon: 'bx bx-user'
    },
    { title: 'Chats', url: '/chats', icon: 'bx bx-message' },
    {
      title: 'Notifications',
      url: '/notifications',
      icon: 'bx bx-bell'
    },
    {
      title: 'Settings',
      url: '/settings',
      icon: 'bx bx-cog'
    }
  ]

  topLeftMenu = [
    { title: 'Calendar', url: '/calendar', icon: 'bx bx-calendar' },
    { title: 'Tasks & ToDos', url: '/tasks', icon: 'bx bx-edit' },
  ]

  profileImage = 'https://res.cloudinary.com/djnqxvljr/image/upload/v1624348663/bank247-removebg-preview_rbeul2.png'

  constructor(private userService: UserService, private tt: ThemeToggleService, private _localStorageService: LocalStorageServiceEncrypt, private router: Router) {
    this.theme = _localStorageService.get('theme')
    if (this.theme !== null) {
      this.switchTheme(this.theme)
    }
    if (this.theme === 'dark') {
      this.isDark = true
    }
    // Get user profile and token
    this.profile = _localStorageService.get('upd')
    this.token = _localStorageService.get('token')
  }

  ngOnInit(): void {
  }

  switchTheme(newTheme: Theme): void {
    this.tt.switchTheme(newTheme);
  }

  onThemeChange(value: boolean) {
    this.isDark = !value
    if (this.isDark) {
      this.switchTheme('dark')
      this._localStorageService.set('theme', 'dark')
    } else {
      this.switchTheme('light')
      this._localStorageService.set('theme', 'light')
    }
  }

  // Sign user out
  async signOut() {
    this._localStorageService.clearAll()
    window.location.reload()
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { Profile } from 'src/app/interfaces/profile';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DispatchRequestService } from 'src/app/services/dispatch-request.service';
import { Theme } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-dispatch-requests',
  templateUrl: './dispatch-requests.component.html',
  styleUrls: ['./dispatch-requests.component.css']
})
export class DispatchRequestsComponent implements OnInit {
  theme: Theme = 'light'
  isDark: boolean = false
  profile: any = {}
  token: string = ""
  year = new Date().getFullYear()
  age: number = 0
  dob: any = ""
  uid: string

  title = "Dispatch Requests"
  icon = "bx bx-cog"
  crumbs = [
    { title: "Dashboard", icon: "bx bx-home", url: "/dashboard" },
    { title: "Dispatch Requests", icon: "bx bx-home", url: "/dispatch/requests" },
    { title: "Overview", icon: "", url: null },
  ]

  pageTitle = 'Dispatch Requests'

  users: Profile[] = [];

  // data Table
  ELEMENT_DATA: Profile[] = []
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns: string[] = [
    'name',
    'email',
    'phoneNumber',
    'role',
    'id'
  ];

  // PAss data source
  dataSource = new MatTableDataSource<Profile>(this.ELEMENT_DATA)

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Profile>;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  constructor(private titleService: Title, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService, private dispatchRequestService: DispatchRequestService, public matDialog: MatDialog, private router: Router, private dashboardService: DashboardService) {
    this.titleService.setTitle(this.pageTitle);

    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
    // Get user profile and token
    this.profile = _localStorageService.get('upd')
    this.token = _localStorageService.get('token')
    this.uid = _localStorageService.get('uid')
  }

  ngOnInit(): void {
    this.fetchData()
  }

  // Fetch All Users
  fetchData() {
    this.dashboardService.getAllUsers().subscribe(res => {
      this.users = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.users.push(item as Profile)
      })
      this.dataSource.data = this.users
    })
  }

  // Filter table
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // Open new account dialog
  viewDispatchRequestSubCollections(data: any) {
    const navigationExtras: NavigationExtras = { state: { data: data } };
    this.router.navigate(['dispatch/request'], navigationExtras);
  }

}

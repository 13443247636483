import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  pageTitle = 'Sign Up'
  isLoggedIn: boolean = false
  isLoading: boolean = false
  hasError: boolean = false
  isReady: boolean = false
  stepOne: boolean = true
  stepTwo: boolean = false
  msg: string = ''
  bvn: string = ''

  passport = 'https://res.cloudinary.com/djnqxvljr/image/upload/v1612431577/p2_ynyocl.jpg'

  profile = {
    bvn: '',
    dateOfBirth: '10-06-1990',
    phoneNumber: '+234 (0) 802 068 9069',
    email: 'abiola.uche@gmail.com',
    Name: 'Abiola Uchechukwu Ahmed',
    gender: 'Male',
    branch: 'Head Quarters, Victoria Island, Lagos',
    address: 'Plot 6, Wisemen Estate Phase II, Lekki, Lagos, Nigeria.'
  }

  info = {
    title: "BVN Verification",
    subtitle: "To verify who you are, please enter you Bank Verification Number (BVN) below"
  }

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private titleService: Title, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private toastr: ToastrService) {

    this.titleService.setTitle(this.pageTitle);

    this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('session'))
    this.currentUser = this.currentUserSubject.asObservable();

    if (localStorage.getItem('session') !== null) {
      this.isLoggedIn = true
      this.router.navigate(['/'])
    }
  }

  ngOnInit(): void {
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  goToStep(step: number) {
    this.profile.bvn = this.form.value.bvn
    console.log(this.form.value.bvn)
    if (step === 2) {
      this.isReady = true
      this.stepOne = false
      this.stepTwo = true
    } else if (step === 1) {
      this.stepTwo = false
      this.stepOne = true
    } else if (step === 0) {
      this.router.navigate(['/signin'])
    }
  }

  form = this.formBuilder.group({
    bvn: ['', { validators: [Validators.required, Validators.email], updateOn: "change" }]
  })

  onSubmit() {
    this.isLoading = true
    this.isLoggedIn = true
    this.toastr.success('Your account creation was successful. Now you can bank seemlessly.', 'Registration Successful')
    localStorage.setItem('session', 'started')
    this.router.navigate(['/'])
  }

}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { Theme } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  theme: Theme = 'light'
  isDark: boolean = false
  profile: any = {}
  token: string = ""
  year = new Date().getFullYear()
  age: number = 0
  dob: any = ""
  uid: string

  title = "Application Support"
  icon = "bx bx-cog"
  crumbs = [
    { title: "Dashboard", icon: "bx bx-home", url: "/dashboard" },
    { title: "Application Settings", icon: "bx bx-home", url: "/app/support" },
    { title: "Contact Developer", icon: "", url: null },
  ]

  pageTitle = 'Application Support'

  developer = {
    name: 'Ernest Ibeh',
    email: 'ernest.ibeh10@gmail.com',
    email2: 'ernest.ibeh@etechds.com.ng',
    phoneNumber: '+2348020689069',
    formattedPhoneNumber: '+234 (0) 802 068 9069',
    imageUrl: 'assets/images/icon.png',
  }

  constructor(private titleService: Title, private _localStorageService: LocalStorageServiceEncrypt,) {
    this.titleService.setTitle(this.pageTitle);

    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
  }

  ngOnInit(): void {
  }

}

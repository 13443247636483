import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { Profile } from 'src/app/interfaces/profile';
import { Theme } from 'src/app/services/theme-toggle.service';
import { SettingsService } from 'src/app/services/settings.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NewUserComponent } from '../diaog/new-user/new-user.component';
import { ViewUserComponent } from '../dialog/view-user/view-user.component';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  theme: Theme = 'light'
  isDark: boolean = false
  profile: any = {}
  token: string = ""
  year = new Date().getFullYear()
  age: number = 0
  dob: any = ""
  uid: string

  title = "Application Settings"
  icon = "bx bx-cog"
  crumbs = [
    { title: "Dashboard", icon: "bx bx-home", url: "/dashboard" },
    { title: "Application Settings", icon: "bx bx-home", url: "/app/settings" },
    { title: "Overview", icon: "", url: null },
  ]

  pageTitle = 'Application Settings'

  users: Profile[] = [];
  totalUsers = 0
  addUserTitle: string = 'Add New User'

  // data Table
  ELEMENT_DATA: Profile[] = []
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns: string[] = [
    'imageUrl',
    'lastName',
    'firstName',
    'email',
    'phone',
    'role',
    'id'
  ];

  // PAss data source
  dataSource = new MatTableDataSource<Profile>(this.ELEMENT_DATA)

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Profile>;



  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  constructor(private titleService: Title, private formBuilder: FormBuilder, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService, private settingsService: SettingsService, public matDialog: MatDialog) {
    this.titleService.setTitle(this.pageTitle);

    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
    // Get user profile and token
    this.profile = _localStorageService.get('upd')
    this.token = _localStorageService.get('token')
    this.uid = _localStorageService.get('uid')
  }

  ngOnInit(): void {
    this.fetchUsers()
  }

  // Fetch All Users
  fetchUsers() {
    this.settingsService.getAllUsers().subscribe(res => {
      this.users = []
      this.totalUsers = 0
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.users.push(item as Profile)
        this.totalUsers++
      })
      this.dataSource.data = this.users
    })
  }

  // Filter table
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // Open new account dialog
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(NewUserComponent, dialogConfig);
  }

  // Open new account dialog
  viewUserDialog(user: any) {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(ViewUserComponent, { disableClose: false, data: user });
  }

}

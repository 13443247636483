<h4 mat-dialog-title>Upate Dispatch Request Price</h4>
<hr>
<div class="row" style="width: 400px">
    <div class="col-md-12">
        <form (ngSubmit)="onSubmit()" name="openAccountForm" [formGroup]="form">
            <div class="form-area">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-field app-shadow">
                            <i class="bx bx-money"></i>
                            <input type="number" placeholder="New Amount" formControlName="amount" #amount
                                (ngModelChange)="modelChange($event)">
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12">
                        <h2>N{{ amountEntered | number}}</h2>
                    </div>
                </div>
            </div>

            <button class="app-btn" (click)="onSubmit()">
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-10" role="status"
                    aria-hidden="true"></span>
                <span *ngIf="isLoading">Processing</span>
                <span *ngIf="!isLoading">Save Changes</span>
            </button>
        </form>
    </div>
</div>
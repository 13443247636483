import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-table-cell',
  templateUrl: './view-table-cell.component.html',
  styleUrls: ['./view-table-cell.component.css']
})
export class ViewTableCellComponent implements OnInit {

  constructor(private matDialog: MatDialog, public dialogRef: MatDialogRef<ViewTableCellComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  // Close modal
  close() {
    this.dialogRef.close()
  }

}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { ToastrService } from 'ngx-toastr';
import { RidersService } from 'src/app/services/riders.service';
import { Theme } from 'src/app/services/theme-toggle.service';

declare const L: any

@Component({
  selector: 'app-track-rider',
  templateUrl: './track-rider.component.html',
  styleUrls: ['./track-rider.component.css']
})
export class TrackRiderComponent implements OnInit, AfterViewInit {

  theme: Theme = 'light'
  isDark: boolean = false

  title = "Track Rider"
  icon = "bx bx-cycling"
  crumbs = [
    { title: "Dashboard", icon: "bx bx-home", url: "/dashboard" },
    { title: "Riders", icon: "bx bx-cycling", url: "/riders" },
    { title: "Tracking Rider", icon: "", url: null },
  ]

  rider: any
  currentRider: any

  tracking: any

  // Use @ViewChild decorator with the template reference variable ‘map’
  @ViewChild('map', { static: false }) mapElement: ElementRef;
  map: google.maps.Map;
  center = new google.maps.LatLng(6.4385, 3.4289);
  marker: google.maps.Marker;
  mapMarkers: any = [];
  markerCluster: any;
  mapOptions: google.maps.MapOptions = {
    center: this.center,
    zoom: 11
  };

  infoWindow: google.maps.InfoWindow;

  locations: any = []

  mapIcon = '../../../assets/images/icon45.png'

  constructor(private http: HttpClient, private router: Router, private titleService: Title, private _localStorageService: LocalStorageServiceEncrypt, private toastr: ToastrService, private riderService: RidersService) {
    const navigation = this.router.getCurrentNavigation()
    const state = navigation?.extras.state as { data: string }
    this.rider = state?.data

    // Check if rID exist on localStorage
    if (this.rider != undefined || this.rider != null) {
      // localStorage.setItem('currentRider', JSON.stringify(this.rider))
      _localStorageService.set('currentRider', this.rider)
    }
    this.currentRider = _localStorageService.get('currentRider');


    this.titleService.setTitle(this.title);

    this.theme = _localStorageService.get('theme')
    if (this.theme === 'dark') {
      this.isDark = true
    }
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.fetchRidersMap();
  }

  fetchRidersMap() {
    this.riderService.getRider(this.currentRider).subscribe(value => {
      if (!navigator.geolocation) {
        console.log('Location not supported')
      }
      navigator.geolocation.getCurrentPosition((position) => {
        const coords = value.position

        let speed = value.position.speed
        let currentAddress = value.currentAddress
        let phone = value.phone
        let name = value.name
        let online = value.online
        let lat = value.lat
        let lng = value.lng

        this.center = new google.maps.LatLng(coords.latitude, coords.longitude)
        this.mapOptions.center = this.center

        // Map Data
        let mapData = {
          position: { lat: lat, lng: lng },
          speed: speed,
          currentAddress: currentAddress,
          phone: phone,
          name: name,
          online: online,
        }

        this.mapInit(mapData)

      })
    });
  }

  // Implement Google map marker
  mapInit(data: any) {
    this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);

    this.infoWindow = new google.maps.InfoWindow()

    const tempMarker = new google.maps.Marker({
      position: data.position,
      map: this.map,
      icon: this.mapIcon
    })

    tempMarker.addListener('click', ((tempMarker: any, map: any, infoWindow: any) => {
      let status = 'Offline'
      if (data.online == true) {
        status = 'Online'
      }
      return () => {
        infoWindow.setContent('<p>Rider: <b> ' + data.name + '</b><br />Phone: <b> ' + data.phone + '</b><br />Status: <b> ' + status + '</b><br />Speed: <b> ' + data.speed + '</b><br />Location: <b> ' + data.currentAddress + '</b></p>');

        infoWindow.open(map, tempMarker)
      }

    })(tempMarker, this.map, this.infoWindow))

    this.mapMarkers.push(tempMarker)

  }

}
